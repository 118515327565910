<template>
  <div :class="logoClass" @click="$router.push({ name: 'home' })">
    <img src="/close-logo.png" alt="close logo" :style="{ height: height + 'px' }" loading="lazy"/>
  </div>
</template>

<script>
export default {
  name: 'LogoComponent',
  props: {
    color: {
      type: String,
      default: 'white'
    },
    height: {
      type: Number,
      default: 90
    }
  },
  computed: {
    logoClass() {
      return {
        'logo-component': true,
        'logo-component--white': this.color === 'white',
        'logo-component--black': this.color === 'black',
        'logo-component--gray': this.color === 'gray'
      }
    }
  }
}
</script>
<style scoped>
.logo-component {
  display: inline-block;
  font-size: 2.4em;
  cursor: pointer;
  animation: fade-in 3s ease-in-out;
}

.logo-component {
  opacity: 0; /* Set initial opacity to 0 */
  animation: fadeIn 1s ease-in-out forwards; /* Use the fadeIn animation for 1 second */
}

@keyframes fadeIn {
  from {
    opacity: 0; /* Start with opacity 0 */
  }
  to {
    opacity: 1; /* End with opacity 1 */
  }
}
.logo-component--white img {
  filter: invert(100%);
}

.logo-component--black {
  filter: invert(20%);
}

.logo-component--gray {
  filter: invert(70%);
}

.logo-component img {
  z-index: 4;
}
</style>
