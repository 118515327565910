<template>
    <LegalPageTemplate>
        <div class="privacy-policy">
            <div class="title">Política de privacidad
            </div>
            <div class="content">
                <h4>Actualizado al 7 de abril de 2023</h4>
                close (“nosotros”, “nuestro” o “nos”) está comprometido en proteger tu privacidad. Esta Política de
                Privacidad explica cómo se recopila, utiliza y divulga tu información personal por parte de close.

                Esta Política de Privacidad se aplica a nuestro sitio web y sus subdominios asociados (colectivamente,
                nuestro "Servicio") junto con nuestra aplicación, close. Al acceder o utilizar nuestro Servicio, indicas
                que
                has leído, entendido y aceptado nuestra recopilación, almacenamiento, uso y divulgación de tu
                información
                personal según se describe en esta Política de Privacidad y nuestros Términos de Servicio.

                <h3>Definiciones y términos clave</h3>
                Para ayudar a explicar las cosas lo más claramente posible en esta Política de Privacidad, cada vez que
                se
                haga referencia a alguno de estos términos, se definirá estrictamente como:

                <ul>
                    <li>Cookie: pequeña cantidad de datos generada por un sitio web y almacenada por su navegador web.
                        Se
                        utiliza para
                        identificar su navegador, proporcionar análisis, recordar información sobre usted, como su
                        preferencia de idioma o
                        información de inicio de sesión.
                    </li>
                    <li>Compañía: cuando esta política menciona "Compañía", "nosotros", "nos" o "nuestro", se refiere a
                        Close, (Calle 93
                        #11-28) que es responsable de su información bajo esta Política de privacidad.
                    </li>
                    <li>País: donde close o los propietarios/fundadores de close tienen su base, en este caso es
                        Colombia.
                    </li>
                    <li>Cliente: se refiere a la empresa, organización o persona que se registra para usar el Servicio
                        close
                        para gestionar
                        las relaciones con sus consumidores o usuarios de servicios.
                    </li>
                    <li>Dispositivo: cualquier dispositivo conectado a Internet, como un teléfono, tableta, computadora
                        o
                        cualquier otro
                        dispositivo que se pueda utilizar para visitar close y utilizar los servicios.
                    </li>
                    <li>Dirección IP: cada dispositivo conectado a Internet se le asigna un número conocido como
                        dirección
                        de protocolo de
                        Internet (IP). Estos números generalmente se asignan en bloques geográficos. A menudo se puede
                        utilizar una dirección
                        IP para identificar la ubicación desde la que un dispositivo se está conectando a Internet.
                    </li>
                    <li>Personal: se refiere a aquellas personas que están empleadas por close o están bajo contrato
                        para
                        realizar un
                        servicio en nombre de una de las partes.
                    </li>
                    <li>Datos personales: cualquier información que permita, directa o indirectamente, o en conexión con
                        otra información,
                        incluyendo un número de identificación personal, la identificación o identificabilidad de una
                        persona natural.
                    </li>
                    <li>Servicio: se refiere al servicio proporcionado por close según se describe en los términos
                        relativos
                        (si están
                        disponibles) y en esta plataforma.
                    </li>
                    <li>Servicio de terceros: se refiere a anunciantes, patrocinadores de concursos, socios
                        promocionales y
                        de marketing y
                        otros que proporcionan nuestro contenido o cuyos productos o servicios creemos que pueden
                        interesarle.
                    </li>
                    <li>Sitio web: el sitio de close, que se puede acceder a través de esta URL: www.close.com.co.</li>
                    <li>Usted: una persona o entidad que está registrada con close para usar los Servicios.</li>
                </ul>

                <h3>¿Qué información recolectamos?</h3>
                Recolectamos información de usted cuando visita nuestro sitio web, se registra en nuestro sitio, realiza
                un
                pedido,
                se suscribe a nuestro boletín de noticias, responde a una encuesta o completa un formulario.
                <ul>
                    <li>Nombre / Nombre de usuario</li>
                    <li>Números de teléfono</li>
                    <li>Direcciones de correo electrónico</li>
                    <li>Direcciones postales</li>
                    <li>Edad</li>
                    <li>Contraseña</li>
                </ul>

                <h3>¿Cómo utilizamos la información que recopilamos?</h3>
                Cualquiera de la información que recopilamos de usted puede ser utilizada de las siguientes maneras:

                <ul>
                    <li>Personalizar su experiencia (su información nos ayuda a responder mejor a sus necesidades
                        individuales)
                    </li>
                    <li>Mejorar nuestro sitio web (nos esforzamos continuamente por mejorar nuestras ofertas en el sitio
                        web
                        en función de la información y comentarios que recibimos de usted)
                    </li>
                    <li>Mejorar el servicio al cliente (su información nos ayuda a responder de manera más efectiva a
                        sus
                        solicitudes de servicio al cliente y necesidades de soporte)
                    </li>
                    <li>Procesar transacciones</li>
                    <li>Administrar un concurso, promoción, encuesta u otra característica del sitio web</li>
                    <li>Enviar correos electrónicos periódicos</li>
                </ul>
                <h3>¿Cuándo usa close información de usuarios finales de terceros?</h3>
                close recopilará los datos de usuario final necesarios para proporcionar los servicios de close a
                nuestros
                clientes.

                Los usuarios finales pueden proporcionarnos voluntariamente información que hayan puesto a disposición
                en
                sitios web de redes sociales. Si nos proporciona alguna información de este tipo, podemos recopilar
                información disponible públicamente de los sitios web de redes sociales que haya indicado. Puede
                controlar
                la cantidad de su información que los sitios web de redes sociales hacen pública visitando estos sitios
                web
                y cambiando sus configuraciones de privacidad.

                <h3>¿Cuándo usa close información de clientes de terceros?</h3>
                Recibimos alguna información de terceros cuando se pone en contacto con nosotros. Por ejemplo, cuando
                nos
                proporciona su dirección de correo electrónico para mostrar interés en convertirse en cliente de close,
                recibimos información de un tercero que proporciona servicios automatizados de detección de fraudes a
                close.
                También recopilamos ocasionalmente información que se hace pública en sitios web de redes sociales.
                Puede
                controlar la cantidad de su información que los sitios web de redes sociales hacen pública visitando
                estos
                sitios web y cambiando sus configuraciones de privacidad.

                <h3>¿Compartimos la información que recopilamos con terceros?</h3>
                <p>
                    Podemos compartir la información que recopilamos, tanto personal como no personal, con terceros como
                    anunciantes, patrocinadores de concursos, socios promocionales y de marketing, y otros que
                    proporcionan
                    nuestro contenido o cuyos productos o servicios creemos que pueden interesarle. También podemos
                    compartirlo
                    con nuestras compañías afiliadas y socios comerciales actuales y futuros, y si estamos involucrados
                    en
                    una
                    fusión, venta de activos u otra reorganización empresarial, también podemos compartir o transferir
                    su
                    información personal y no personal a nuestros sucesores en interés.

                </p>
                Podemos contratar a proveedores de servicios de confianza de terceros para realizar funciones y
                proporcionar
                servicios para nosotros, como alojamiento y mantenimiento de nuestros servidores y sitio web,
                almacenamiento
                y gestión de bases de datos, gestión de correo electrónico, almacenamiento de marketing, procesamiento
                de
                tarjetas de crédito, servicio al cliente y cumplimiento de pedidos de productos y servicios que puede
                comprar a través del sitio web. Es probable que compartamos su información personal, y posiblemente
                alguna
                información no personal, con estos terceros para que puedan realizar estos servicios para nosotros y
                para
                usted.

                <p>
                    Podemos compartir partes de nuestros registros de datos, incluidas las direcciones IP, con terceros
                    como
                    socios de análisis web, desarrolladores de aplicaciones y redes publicitarias para fines analíticos.
                    Si
                    se
                    comparte su dirección IP, puede ser utilizada para estimar su ubicación general y otros datos
                    técnicos
                    como
                    la velocidad de conexión, si ha visitado el sitio web en una ubicación compartida y el tipo de
                    dispositivo
                    utilizado para visitar el sitio web. Pueden recopilar información sobre nuestra publicidad y lo que
                    ve
                    en el
                    sitio web y luego proporcionar auditoría, investigación e informes para nosotros y nuestros
                    anunciantes.

                </p>

                También podemos divulgar información personal y no personal sobre usted a funcionarios del gobierno o de
                la
                aplicación de la ley o a terceros privados, si creemos, a nuestro exclusivo criterio, que es necesario o
                apropiado para responder a reclamos, procesos legales (incluyendo citaciones), para proteger nuestros
                derechos e intereses o los de un tercero, la seguridad del público o de cualquier persona, para prevenir
                o
                detener cualquier actividad ilegal, poco ética o legalmente sancionable, o para cumplir con las órdenes,
                leyes, reglas y regulaciones judiciales aplicables.

                <p>
                    Close recopilará información personal que nos envíe. También podemos recibir información personal
                    sobre
                    usted de terceros como se describe anteriormente.

                </p>

                Al enviar su dirección de correo electrónico en este sitio web, acepta recibir correos electrónicos de
                nuestra parte. Puede cancelar su participación en cualquiera de estas listas de correo electrónico en
                cualquier momento haciendo clic en el enlace de exclusión voluntaria o en otra opción de cancelación de
                suscripción que se incluye en el correo electrónico correspondiente. Solo enviamos correos electrónicos
                a
                personas que nos han autorizado a contactarlos, ya sea directamente o a través de un tercero. No
                enviamos
                correos electrónicos comerciales no solicitados porque odiamos el correo no deseado tanto como usted. Al
                enviar su dirección de correo electrónico, también acepta permitirnos utilizar su dirección de correo
                electrónico para dirigirnos a una audiencia personalizada en sitios como Facebook, donde mostramos
                publicidad personalizada a personas específicas que se han registrado para recibir comunicaciones de
                nuestra
                parte. Las direcciones de correo electrónico enviadas solo a través de la página de procesamiento de
                pedidos
                se utilizarán únicamente con el fin de enviarle información y actualizaciones relacionadas con su
                pedido.
                Sin embargo, si nos ha proporcionado la misma dirección de correo electrónico a través de otro método,
                podemos usarla para cualquiera de los fines indicados en esta Política. Nota: Si en algún momento desea
                darse de baja para dejar de recibir correos electrónicos futuros, incluimos instrucciones detalladas
                para
                darse de baja al final de cada correo electrónico.

                <h3>¿Por cuánto tiempo mantenemos su información?</h3>
                Mantenemos su información solo mientras la necesitamos para proporcionarle los servicios de Close y
                cumplir
                con los propósitos descritos en esta política. Esto también se aplica a cualquier persona con la que
                compartamos su información y que lleve a cabo servicios en nuestro nombre. Cuando ya no necesitemos usar
                su
                información y no haya necesidad de mantenerla para cumplir con nuestras obligaciones legales o
                regulatorias,
                la eliminaremos de nuestros sistemas o la despersonalizaremos para que no podamos identificarlo.

                <h3>¿Cómo protegemos su información?</h3>
                Implementamos una variedad de medidas de seguridad para mantener la seguridad de su información personal
                cuando realiza un pedido o ingresa, envía o accede a su información personal. Ofrecemos el uso de un
                servidor seguro. Toda la información sensible / de crédito suministrada se transmite a través de la
                tecnología Secure Socket Layer (SSL) y luego se cifra en la base de datos de nuestros proveedores de
                servicios de pago, a la que solo pueden acceder aquellos autorizados con derechos de acceso especiales a
                dichos sistemas y que están obligados a mantener la información confidencial. Después de una
                transacción, su
                información privada (tarjetas de crédito, números de seguridad social, información financiera, etc.)
                nunca
                se guarda en nuestro sistema. Sin embargo, no podemos garantizar la seguridad absoluta de ninguna
                información que transmita a Close, ni garantizar que su información en el Servicio no pueda ser
                accedida,
                divulgada, alterada o destruida por una violación de cualquiera de nuestras medidas de seguridad
                físicas,
                técnicas o administrativas.

                <h3>¿Podría transferirse mi información a otros países?</h3>
                Close está incorporada en Colombia. La información recopilada a través de nuestro sitio web, mediante
                interacciones directas con usted o del uso de nuestros servicios de ayuda, puede ser transferida
                periódicamente a nuestras oficinas o personal, o a terceros, ubicados en todo el mundo, y puede ser
                vista y
                alojada en cualquier lugar del mundo, incluidos países que pueden no tener leyes de aplicación general
                que
                regulen el uso y la transferencia de dichos datos. En la medida máxima permitida por la ley aplicable,
                mediante el uso de cualquiera de los servicios de Close, usted consiente voluntariamente en la
                transferencia
                y alojamiento transfronterizo de dicha información.

                <h3>¿Es segura la información recopilada a través del Servicio de Close?</h3>
                Tomamos precauciones para proteger la seguridad de su información. Contamos con procedimientos físicos,
                electrónicos y administrativos para ayudar a salvaguardar, prevenir el acceso no autorizado, mantener la
                seguridad de los datos y utilizar correctamente su información. Sin embargo, ni las personas ni los
                sistemas
                de seguridad son infalibles, incluidos los sistemas de cifrado. Además, las personas pueden cometer
                delitos
                intencionales, cometer errores o no seguir políticas. Por lo tanto, si bien hacemos esfuerzos razonables
                para proteger su información personal, no podemos garantizar su seguridad absoluta. Si la ley aplicable
                impone algún deber no renunciable de proteger su información personal, usted acepta que el
                comportamiento
                intencional será el estándar utilizado para medir nuestro cumplimiento con ese deber.

                <h3>¿Puedo actualizar o corregir mi información?</h3>
                <p>
                    Los derechos que tienes para solicitar actualizaciones o correcciones a la información que close
                    recopila
                    dependen de tu relación con close. El personal puede actualizar o corregir su información según se
                    detalla
                    en nuestras políticas internas de empleo de la empresa.
                </p>

                Los clientes tienen derecho a solicitar la restricción de ciertos usos y divulgaciones de información
                personalmente identificable como se describe a continuación. Puedes contactarnos para (1) actualizar o
                corregir tu información personalmente identificable, (2) cambiar tus preferencias con respecto a las
                comunicaciones y otra información que recibes de nosotros, o (3) eliminar la información personalmente
                identificable mantenida sobre ti en nuestros sistemas (sujeto al siguiente párrafo), cancelando tu
                cuenta.
                Tales actualizaciones, correcciones, cambios y eliminaciones no tendrán efecto en otra información que
                mantengamos, o información que hayamos proporcionado a terceros de acuerdo con esta Política de
                privacidad
                antes de dicha actualización, corrección, cambio o eliminación. Para proteger tu privacidad y seguridad,
                podemos tomar medidas razonables (como solicitar una contraseña única) para verificar tu identidad antes
                de
                otorgarte acceso al perfil o realizar correcciones. Eres responsable de mantener en secreto tu
                contraseña
                única y la información de tu cuenta en todo momento.

                <p>
                    Debes ser consciente de que no es tecnológicamente posible eliminar cada uno de los registros de la
                    información que nos has proporcionado de nuestro sistema. La necesidad de hacer una copia de
                    seguridad
                    de
                    nuestros sistemas para proteger la información de pérdidas involuntarias significa que puede existir
                    una
                    copia de tu información en una forma no borrable que será difícil o imposible para nosotros
                    localizar.
                    Inmediatamente después de recibir tu solicitud, toda la información personal almacenada en las bases
                    de
                    datos que utilizamos activamente y en otros medios fácilmente buscables se actualizará, corregirá,
                    cambiará
                    o eliminará, según corresponda, tan pronto como sea razonable y técnicamente factible.
                </p>

                Si eres un usuario final y deseas actualizar, eliminar o recibir cualquier información que tengamos
                sobre
                ti, puedes hacerlo contactando a la organización de la que eres cliente.

                <h3>Personal</h3>
                <p>
                    Si eres un trabajador o solicitante de close, recopilamos información que nos proporcionas
                    voluntariamente.
                    Utilizamos la información recopilada con fines de recursos humanos para administrar beneficios a los
                    trabajadores y para evaluar solicitantes.
                </p>

                <p>
                    Puedes contactarnos para (1) actualizar o corregir tu información, (2) cambiar tus preferencias con
                    respecto
                    a las comunicaciones y otra información que recibes de nosotros, o (3) recibir un registro de la
                    información
                    que tenemos relacionada contigo. Tales actualizaciones, correcciones, cambios y eliminaciones no
                    tendrán
                    efecto en otra información que mantengamos, o información que hayamos proporcionado a terceros de
                    acuerdo
                    con esta Política de privacidad antes de dicha actualización, corrección, cambio o eliminación.
                </p>

                <h3>Venta de negocio</h3>
                <p>
                    Nos reservamos el derecho de transferir información a un tercero en caso de venta, fusión u otra
                    transferencia de todos o sustancialmente todos los activos de close o cualquiera de sus Afiliados
                    Corporativos (según se define en este documento), o esa parte de close o cualquiera de sus Afiliados
                    Corporativos a la que se refiere el Servicio, o en el caso de que descontinuemos nuestro negocio o
                    presentemos o tengamos presentada en contra nuestra una petición de bancarrota, reorganización o
                    procedimiento similar, siempre y cuando el tercero acepte adherirse a los términos de esta Política
                    de
                    privacidad.
                </p>

                <h3>Afiliados</h3>
                Podemos divulgar información (incluyendo información personal) sobre usted a nuestros Afiliados
                Corporativos. A los efectos de esta Política de privacidad, "Afiliado Corporativo" significa cualquier
                persona o entidad que controla directa o indirectamente, es controlada por o está bajo control común con
                close, ya sea por propiedad o de otra manera. Cualquier información relacionada con usted que
                proporcionemos
                a nuestros Afiliados Corporativos será tratada por esos Afiliados Corporativos de acuerdo con los
                términos
                de esta Política de privacidad.

                <h3>Ley aplicable</h3>
                Esta Política de privacidad está regida por las leyes de Colombia sin tener en cuenta sus disposiciones
                sobre conflictos de leyes. Usted acepta la jurisdicción exclusiva de los tribunales en relación con
                cualquier acción o disputa que surja entre las partes en virtud o en relación con esta Política de
                privacidad, excepto para aquellas personas que puedan tener derechos para presentar reclamaciones en
                virtud
                del Escudo de privacidad o el marco suizo-estadounidense.

                <p>
                    Las leyes de Colombia, excluyendo sus reglas de conflicto de leyes, regirán este Acuerdo y su uso
                    del
                    sitio
                    web. Su uso del sitio web también puede estar sujeto a otras leyes locales, estatales, nacionales o
                    internacionales.
                </p>

                Al utilizar close o contactarnos directamente, usted indica su aceptación de esta Política de
                privacidad. Si
                no está de acuerdo con esta Política de privacidad, no debe interactuar con nuestro sitio web ni
                utilizar
                nuestros servicios. El uso continuado del sitio web, la interacción directa con nosotros, o seguir los
                cambios publicados en esta Política de privacidad que no afecten significativamente el uso o divulgación
                de
                su información personal significará que acepta esos cambios.

                <h3>Your Consent</h3>
                Hemos actualizado nuestra Política de Privacidad para proporcionarte completa transparencia sobre lo que
                se
                establece cuando visitas nuestro sitio y cómo se está utilizando. Al utilizar nuestro sitio web,
                registrar
                una cuenta o realizar una compra, usted acepta nuestra Política de privacidad y acepta sus términos.


                <h3>Enlaces a otros sitios web</h3>

                Esta Política de privacidad se aplica solo a los Servicios. Los Servicios pueden contener enlaces a
                otros
                sitios web que no son operados ni controlados por close. No somos responsables del contenido, la
                precisión o
                las opiniones expresadas en dichos sitios web, y dichos sitios web no son investigados, monitoreados o
                verificados por nosotros en cuanto a su precisión o integridad. Recuerde que cuando usa un enlace para
                ir de
                los Servicios a otro sitio web, nuestra Política de privacidad ya no está en vigencia. Su navegación e
                interacción en cualquier otro sitio web, incluidos aquellos que tienen un enlace en nuestra plataforma,
                están sujetos a las propias reglas y políticas de ese sitio web. Dichos terceros pueden utilizar sus
                propias
                cookies u otros métodos para recopilar información sobre usted.


                <h3>Publicidad</h3>

                Este sitio web puede contener publicidad de terceros y enlaces a sitios de terceros. close no hace
                ninguna
                representación sobre la precisión o conveniencia de la información contenida en esos anuncios o sitios y
                no
                acepta ninguna responsabilidad o responsabilidad por la conducta o contenido de esos anuncios y sitios y
                las
                ofertas realizadas por terceros.

                La publicidad mantiene close y muchos de los sitios web y servicios que utiliza de forma gratuita.
                Trabajamos duro para asegurarnos de que los anuncios sean seguros, discretos y lo más relevantes
                posible.

                La publicidad de terceros y los enlaces a otros sitios donde se anuncian bienes o servicios no son
                recomendaciones ni recomendaciones de close de los sitios, bienes o servicios de terceros. close no se
                hace
                responsable del contenido de ninguno de los anuncios, promesas realizadas o la calidad/confiabilidad de
                los
                productos o servicios ofrecidos en todos los anuncios.

                <h3>Cookies para Publicidad</h3>

                Estas cookies recopilan información con el tiempo sobre tu actividad en línea en el sitio web y otros
                servicios en línea para hacer que los anuncios en línea sean más relevantes y efectivos para ti. Esto se
                conoce como publicidad basada en intereses. También realizan funciones como evitar que el mismo anuncio
                aparezca continuamente y asegurarse de que los anuncios se muestren correctamente para los anunciantes.
                Sin
                cookies, es realmente difícil para un anunciante llegar a su audiencia o saber cuántos anuncios se
                mostraron
                y cuántos clics recibieron.

                <h3>Cookies</h3>

                close utiliza "Cookies" para identificar las áreas de nuestro sitio web que has visitado. Una Cookie es
                un
                pequeño fragmento de datos almacenado en tu computadora o dispositivo móvil por tu navegador web.
                Utilizamos
                cookies para mejorar el rendimiento y la funcionalidad de nuestro sitio web, pero no son esenciales para
                su
                uso. Sin embargo, sin estas cookies, ciertas funcionalidades como videos pueden volverse no disponibles
                o se
                te pedirá que ingreses tus detalles de inicio de sesión cada vez que visites el sitio web, ya que no
                podremos recordar que ya habías iniciado sesión anteriormente. La mayoría de los navegadores web pueden
                configurarse para desactivar el uso de cookies. Sin embargo, si desactivas las cookies, es posible que
                no
                puedas acceder a la funcionalidad de nuestro sitio web correctamente o en absoluto. Nunca colocamos
                información de identificación personal en las cookies.

                <h3>Bloqueo y desactivación de cookies y tecnologías similares</h3>

                Dondequiera que te encuentres, también puedes configurar tu navegador para bloquear cookies y
                tecnologías
                similares, pero esta acción puede bloquear nuestras cookies esenciales y evitar que nuestro sitio web
                funcione correctamente, y es posible que no puedas utilizar todas sus características y servicios por
                completo. También debes saber que es posible que pierdas cierta información guardada (por ejemplo,
                detalles
                de inicio de sesión guardados, preferencias del sitio) si bloqueas las cookies en tu navegador.
                Diferentes
                navegadores ofrecen diferentes controles. Deshabilitar una cookie o categoría de cookie no elimina la
                cookie
                de tu navegador; tendrás que hacerlo tú mismo desde dentro de tu navegador. Debes visitar el menú de
                ayuda
                de tu navegador para obtener más información.

                <h3>Servicios de Remarketing</h3>

                Utilizamos servicios de remarketing. ¿Qué es el remarketing? En el marketing digital, el remarketing (o
                retargeting) es la práctica de servir anuncios en internet a personas que ya han visitado tu sitio web.
                Permite que tu empresa parezca que está "siguiendo" a las personas por internet al servir anuncios en
                los
                sitios web y plataformas que utilizan más.

                <h3>Privacidad de los niños</h3>

                Recopilamos información de niños menores de 13 años solo para mejorar nuestros servicios. Si eres un
                padre o
                tutor y eres consciente de que tu hijo nos ha proporcionado datos personales sin tu permiso,
                contáctanos. Si
                nos damos cuenta de que hemos recopilado datos personales de cualquier persona menor de 13 años sin la
                verificación del consentimiento de los padres, tomamos medidas para eliminar esa información de nuestros
                servidores.

                <h3>Cambios en nuestra Política de Privacidad</h3>

                <p>Podemos cambiar nuestro Servicio y nuestras políticas, y es posible que necesitemos hacer cambios en
                    esta
                    Política de Privacidad para que reflejen con precisión nuestro Servicio y nuestras políticas. A
                    menos
                    que se requiera lo contrario por ley, le notificaremos (por ejemplo, a través de nuestro Servicio)
                    antes
                    de realizar cambios en esta Política de Privacidad y le daremos la oportunidad de revisarlos antes
                    de
                    que entren en vigor. Entonces, si continúa utilizando el Servicio, quedará vinculado por la Política
                    de
                    Privacidad actualizada. Si no desea aceptar esta Política de Privacidad o cualquier Política de
                    Privacidad actualizada, puede eliminar su cuenta.</p>

                <h3>Servicios de terceros</h3>

                <p>Podemos mostrar, incluir o poner a disposición contenido de terceros (incluidos datos, información,
                    aplicaciones y otros productos y servicios) o proporcionar enlaces a sitios web o servicios de
                    terceros
                    ("Servicios de terceros"). Usted reconoce y acepta que close no será responsable de ningún Servicio
                    de
                    terceros, incluida su exactitud, integridad, puntualidad, validez, cumplimiento de derechos de
                    autor,
                    legalidad, decencia, calidad o cualquier otro aspecto relacionado. close no asume y no tendrá
                    ninguna
                    responsabilidad ni obligación con usted ni con ninguna otra persona o entidad por ningún Servicio de
                    terceros. Los Servicios de terceros y los enlaces a ellos se proporcionan únicamente como
                    conveniencia
                    para usted, y usted los accede y los utiliza bajo su propio riesgo y sujeto a los términos y
                    condiciones
                    de dichos terceros.</p>

                <h3>Tecnologías de seguimiento</h3>

                <h4>-Cookies</h4>

                <p>Utilizamos cookies para mejorar el rendimiento y la funcionalidad de nuestro sitio web, pero no son
                    esenciales para su uso. Sin embargo, sin estas cookies, es posible que ciertas funcionalidades, como
                    los
                    videos, no estén disponibles o se le solicite que ingrese sus datos de inicio de sesión cada vez que
                    visite el sitio web, ya que no podríamos recordar que ha iniciado sesión anteriormente.</p>

                <h4>-Sesiones</h4>

                <p>close utiliza "Sesiones" para identificar las áreas de nuestro sitio web que ha visitado. Una Sesión
                    es
                    un pequeño fragmento de datos almacenado en su computadora o dispositivo móvil por su navegador
                    web.</p>

                <h3>Información sobre el Reglamento General de Protección de Datos (RGPD)</h3>

                <p>Es posible que estemos recopilando y utilizando información de usted si se encuentra en el Espacio
                    Económico Europeo (EEE), y en esta sección de nuestra Política de Privacidad vamos a explicar
                    exactamente cómo y por qué se recopila estos datos, y cómo mantenemos estos datos protegidos para
                    evitar
                    su replicación o uso indebido.</p>

                <h3>¿Qué es el RGPD?</h3>


                <p>El RGPD es una ley de privacidad y protección de datos en toda la UE que regula cómo las empresas
                    protegen los datos de los residentes de la UE y aumenta el control que tienen los residentes de la
                    UE
                    sobre sus datos personales.
                </p>

                <p> El RGPD es relevante para cualquier empresa que opere a nivel global y no solo para las empresas con
                    sede
                    en la UE y los resident
                </p>

                <h3>¿Qué es la información personal?</h3>

                <p>
                    Cualquier información que se relacione con una persona física identificada o identificable. El RGPD
                    cubre un
                    amplio espectro de información que podría usarse por sí sola o en combinación con otras piezas de
                    información para identificar a una persona. Los datos personales van más allá del nombre o dirección
                    de
                    correo electrónico de una persona. Algunos ejemplos incluyen información financiera, opiniones
                    políticas,
                    datos genéticos, datos biométricos, direcciones IP, dirección física, orientación sexual y etnia.
                </p>

                <h3>Los principios de protección de datos incluyen requisitos como:</h3>

                <ul>
                    <li>Los datos personales recopilados deben procesarse de manera justa, legal y transparente y solo
                        deben
                        utilizarse de una manera que una persona razonablemente esperaría.
                    </li>
                    <li>Los datos personales solo deben recopilarse para cumplir un propósito específico y solo deben
                        usarse
                        para ese propósito. Las organizaciones deben especificar por qué necesitan los datos personales
                        cuando los recopilan.
                    </li>
                    <li>Los datos personales no deben mantenerse por más tiempo del necesario para cumplir su propósito.
                    </li>
                    <li>Las personas cubiertas por el RGPD tienen derecho a acceder a sus propios datos personales.
                        También
                        pueden solicitar una copia de sus datos y que se actualicen, eliminen, restrinjan o transfieran
                        a
                        otra organización.
                    </li>
                </ul>

                <h3>¿Por qué es importante el RGPD?</h3>

                El RGPD agrega algunos requisitos nuevos con respecto a cómo las empresas deben proteger los datos
                personales de las personas que recopilan y procesan. También aumenta las sanciones por incumplimiento.
                Además, es simplemente lo correcto. En close creemos firmemente que la privacidad de sus datos es muy
                importante y ya tenemos sólidas prácticas de seguridad y privacidad que van más allá de los requisitos
                de
                esta nueva regulación.


                <h3>Derechos del sujeto de datos individuales: acceso, portabilidad y eliminación de datos</h3>

                <p>Nos comprometemos a ayudar a nuestros clientes a cumplir con los requisitos de derechos de los
                    sujetos de
                    datos del GDPR. Cerramos procesos o almacenamos todos los datos personales en proveedores
                    completamente
                    evaluados y compatibles con DPA. Almacenamos todas las conversaciones y los datos personales durante
                    un
                    máximo de 6 años, a menos que se elimine su cuenta. En ese caso, desechamos todos los datos de
                    acuerdo
                    con nuestros Términos de servicio y Política de privacidad, pero no los conservaremos por más de 60
                    días.</p>

                <p>Sabemos que si trabaja con clientes de la UE, debe poder proporcionarles la capacidad de acceder,
                    actualizar, recuperar y eliminar datos personales. ¡Nosotros lo hacemos! Hemos sido configurados
                    como
                    autoservicio desde el principio y siempre le hemos dado acceso a sus datos y los datos de sus
                    clientes.
                    Nuestro equipo de soporte al cliente está aquí para responder cualquier pregunta que pueda tener
                    sobre
                    el uso de la API.</p>


                <h3>Residentes de California</h3>

                <p>La Ley de Privacidad del Consumidor de California (CCPA) requiere que divulguemos las categorías de
                    información personal que recopilamos y cómo la usamos, las categorías de fuentes de las cuales
                    recopilamos información personal y los terceros con los que la compartimos, lo cual hemos explicado
                    anteriormente.</p>

                <p>También estamos obligados a comunicar información sobre los derechos que los residentes de California
                    tienen bajo la ley de California. Puede ejercer los siguientes derechos:</p>

                <ul>
                    <li>Derecho a Saber y Acceder. Puede enviar una solicitud verificable de información sobre: (1) las
                        categorías de información personal que recopilamos, usamos o compartimos; (2) los fines para los
                        que
                        se recopilan o utilizan las categorías de información personal; (3) las categorías de fuentes de
                        las
                        cuales recopilamos información personal; y (4) las piezas específicas de información personal
                        que
                        hemos recopilado sobre usted.
                    </li>
                    <li>Derecho a un servicio igualitario. No discriminaremos contra usted si ejerce sus derechos de
                        privacidad.
                    </li>
                    <li>Derecho a eliminar. Puede enviar una solicitud verificable para cerrar su cuenta y eliminaremos
                        la
                        información personal sobre usted que hemos recopilado.
                    </li>
                    <li>Solicitar que un negocio que vende la información personal de un consumidor no venda la
                        información
                        personal del consumidor.
                    </li>
                </ul>

                <h3>Derechos de los Sujetos de Datos Individuales - Acceso, Portabilidad y Eliminación de Datos</h3>

                Estamos comprometidos a ayudar a nuestros clientes a cumplir con los requisitos de los derechos de los
                sujetos de datos del GDPR. Cerramos procesos o almacenamos todos los datos personales en proveedores
                completamente evaluados y compatibles con la DPA. Almacenamos todas las conversaciones y los datos
                personales hasta por 6 años, a menos que se elimine su cuenta. En ese caso, desechamos todos los datos
                de
                acuerdo con nuestros Términos de Servicio y Política de Privacidad, pero no los retendremos por más de
                60
                días.

                Somos conscientes de que si trabaja con clientes de la UE, debe proporcionarles la capacidad de acceder,
                actualizar, recuperar y eliminar datos personales. ¡Lo tenemos cubierto! Nos hemos configurado como
                autoservicio desde el principio y siempre le hemos dado acceso a sus datos y los datos de sus clientes.
                Nuestro equipo de soporte al cliente está aquí para responder cualquier pregunta que pueda tener sobre
                cómo
                trabajar con la API.

                <h3>Residentes de California</h3>

                La Ley de Privacidad del Consumidor de California (CCPA) nos exige revelar las categorías de Información
                Personal que recopilamos y cómo la utilizamos, las categorías de fuentes de las que recopilamos
                Información
                Personal y las terceras partes con las que la compartimos, lo que ya hemos explicado.

                También se nos exige comunicar información sobre los derechos que tienen los residentes de California en
                virtud de la ley de California. Puede ejercer los siguientes derechos:

                <ul>
                    <li>Derecho a Saber y Acceder. Puede presentar una solicitud verificable de información sobre: (1)
                        las
                        categorías de Información Personal que recopilamos, usamos o compartimos; (2) los fines para los
                        que
                        recopilamos o utilizamos las categorías de Información Personal; (3) las categorías de fuentes
                        de
                        las que recopilamos Información Personal; y (4) piezas específicas de Información Personal que
                        hemos
                        recopilado sobre usted.
                    </li>
                    <li>Derecho a Igualdad de Servicio. No lo discriminaremos si ejerce sus derechos de privacidad.</li>
                    <li>Derecho a Eliminar. Puede presentar una solicitud verificable para cerrar su cuenta y
                        eliminaremos
                        la Información Personal sobre usted que hayamos recopilado.
                    </li>
                    <li>Solicitar que una empresa que vende los datos personales de un consumidor no venda los datos
                        personales del consumidor.
                    </li>
                </ul>

                Si hace una solicitud, tenemos un mes para responderle. Si desea ejercer alguno de estos derechos,
                contáctenos. No vendemos la Información Personal de nuestros usuarios. Para obtener más información
                sobre
                estos derechos, contáctenos.

                <h3>Ley de Protección de la Privacidad en Línea de California (CalOPPA)</h3>

                CalOPPA nos exige revelar las categorías de Información Personal que recopilamos y cómo la utilizamos,
                las
                categorías de fuentes de las que recopilamos Información Personal y las terceras partes con las que la
                compartimos, lo que ya hemos explicado.

                <h3>Los usuarios de CalOPPA tienen los siguientes derechos:</h3>
                <ul>
                    <li>Derecho a saber y acceder. Puede presentar una solicitud verificable de información sobre: (1)
                        las
                        categorías
                        de Información Personal que recopilamos, usamos o compartimos; (2) los fines para los que se
                        recopilan o
                        usan las categorías de Información Personal por nosotros; (3) las categorías de fuentes de las
                        que
                        recopilamos
                        Información Personal; y (4) las piezas específicas de Información Personal que hemos recopilado
                        sobre usted.
                    </li>
                    <li>Derecho a igualdad de servicios. No discriminaremos contra usted si ejerce sus derechos de
                        privacidad.
                    </li>
                    <li>Derecho a borrar. Puede presentar una solicitud verificable para cerrar su cuenta y eliminaremos
                        la
                        Información
                        Personal que hemos recopilado sobre usted.
                    </li>
                    <li>Derecho a solicitar que un negocio que vende datos personales de un consumidor no venda los
                        datos
                        personales
                        del consumidor.
                    </li>
                </ul>

                Si realiza una solicitud, tenemos un mes para responderle. Si desea ejercer alguno de estos derechos,
                contáctenos.

                No vendemos la Información Personal de nuestros usuarios.

                Para obtener más información sobre estos derechos, contáctenos.

                <h3>Contáctenos</h3>

                No dude en contactarnos si tiene alguna pregunta.

                <ul>
                    <li>Via Email: pqrs@close.com.co</li>
                </ul>

            </div>
        </div>

    </LegalPageTemplate>
</template>

<script>
import LegalPageTemplate from "@/pages/legal/LegalPageTemplate.vue";

export default {
    name: "AboutUsPage",
    components: {LegalPageTemplate}
}
</script>

<style scoped>
</style>