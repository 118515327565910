import {createRouter, createWebHistory} from 'vue-router'

import HomePage from "@/pages/HomePage.vue";
import MarketplacePage from "@/pages/MarketplacePage.vue";
import AboutUsPage from "@/pages/AboutUsPage.vue";
import CookiePolicyPage from "@/pages/legal/CookiePolicyPage.vue";
import TermsAndConditionsPage from "@/pages/legal/TermsAndConditionsPage.vue";
import PrivacyPolicyPage from "@/pages/legal/PrivacyPolicyPage.vue";
import BePartOfOurCommunityPage from "@/pages/BePartOfOurCommunityPage.vue";
import HelpPage from "@/pages/help/HelpPageHome.vue";
import RemoveShop from "@/pages/help/RemoveShop.vue";
import PQRPage from "@/pages/help/PQRPage.vue";
import ReportShop from "@/pages/help/ReportShop.vue";
import ProposeShop from "@/pages/help/ProposeShop.vue";


const routes = [
    {
        path: "/",
        name: "home",
        component: HomePage
    },
    {
        path: "/shop",
        name: "shop",
        component: MarketplacePage
    },
    {
        path: "/about-us",
        name: "about-us",
        component: AboutUsPage
    },
    {
        path: "/legal/cookie-policy",
        name: "cookie-policy",
        component: CookiePolicyPage
    },
    {
        path: "/legal/terms-and-conditions",
        name: "terms-and-conditions",
        component: TermsAndConditionsPage
    },
    {
        path: "/legal/privacy-policy",
        name: "privacy-policy",
        component: PrivacyPolicyPage
    },
    {
        path: "/join-us",
        name: "join-us",
        component: BePartOfOurCommunityPage
    },
    {
        path: "/help",
        name: "help",
        component: HelpPage
    },
    {
        path: "/help/remove-shop",
        name: "remove-shop",
        component: RemoveShop
    },
    {
        path: "/help/report-shop",
        name: "report-shop",
        component: ReportShop
    },
    {
        path: "/help/propose-shop",
        name: "propose-shop",
        component: ProposeShop
    },
    {
        path: "/help/pqrs",
        name: "pqrs",
        component: PQRPage
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router;

