<template>
    <div>
        <h1>Close</h1>
        <AnnouncementsBar></AnnouncementsBar>
        <router-view></router-view>
        <FooterComponent></FooterComponent>
    </div>
</template>

<script>
import FooterComponent from "@/components/general/FooterComponent.vue";
import AnnouncementsBar from "@/components/general/AnnouncementsBar.vue";

export default {
    name: 'App',
    mounted() {
        // if (navigator.geolocation) {
        //     navigator.geolocation.getCurrentPosition(position => {
        //         this.location = {
        //             latitude: position.coords.latitude,
        //             longitude: position.coords.longitude
        //         };
        //     });
        // } else {
        //     alert("Geolocation is not supported by this browser.");
        // }
    },
    components: {
        AnnouncementsBar,
        FooterComponent,
    },
}
</script>

<style>

#app {
    font-family: 'Inter', sans-serif;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    width: 100%;
}

body {
    width: 100%;
    margin: 0;
    padding: 0;
}

:root {
    --primary: #DBEB2D;
    --progress-bar-color: #DBEB2D;
    --background-gray: #fafafa;
    --background-dark-gray: #dfdfdf;
    --main-color: gold;
    --starship: #DBEB2D;
    --starship-dark: #a8b518;
    --brand-primary: #DBEB2D;
    --brand-secondary-dark: #DBEB2D;
    --brand-secondary-dark-hover: #a8b518;
}

h1 {
    display: none;
}

h3 {
    font-weight: normal;
}

h4 {
    font-weight: normal;
}


.Vue-Toastification__toast--info.toast-ai {
    background: #fdfdfdf0;
    color: #303030;
}

.Vue-Toastification__toast--info.toast-ai {
    background: #fffffff0;
    color: #303030;
}

.Vue-Toastification__toast-body {
    font-family: 'Inter', sans-serif !important;
}

.Vue-Toastification__toast-body {
    font-family: 'Inter', sans-serif !important;
}

</style>
