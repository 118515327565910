<template>
    <HelpPageTemplate>
        <div class="help-page">
            <div class="title">Ayuda / PQRS</div>
            <div class="help-sections">
                <div class="help-sections-title">Tiendas</div>
                <div class="help-section">
                    <div class="help-section-title">
                        <a href="/help/remove-shop">Quiero dar de baja mi tienda</a>
                    </div>
                </div>
                <!--            <div class="help-section">-->
                <!--                <div class="help-section-title">-->
                <!--                    <a href="/help/close-friends">Quiero editar mis productos</a>-->
                <!--                </div>-->
                <!--            </div>-->
                <!--            <div class="help-section">-->
                <!--                <div class="help-section-title">-->
                <!--                    <a href="/help/close-friends">Quiero iniciar una campaña de publicidad</a>-->
                <!--                </div>-->
                <!--            </div>-->
            </div>
            <div class="help-sections">
                <div class="help-sections-title">Usuarios</div>
                <div class="help-section">
                    <div class="help-section-title"><a href="/help/propose-shop">Quiero proponer una tienda</a></div>
                </div>
                <div class="help-section">
                    <div class="help-section-title"><a href="/help/report-shop">Quiero reportar una tienda</a></div>
                </div>
            </div>
            <div class="help-sections">
                <div class="help-sections-title">Otra</div>
                <div class="help-section">
                    <div class="help-section-title"><a href="/help/pqrs">Redactar un PQR</a></div>
                </div>
            </div>
        </div>
    </HelpPageTemplate>
</template>

<script>
import HelpPageTemplate from "@/pages/help/HelpPageTemplate.vue";

export default {
    name: "HelpPageHome",
    components: {HelpPageTemplate}
}
</script>

<style scoped>

.help-section {
    background-color: #f3f3f3;
    border-radius: 10px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.help-page .help-sections-title {
    font-size: 1.5em;
    text-align: left;
    font-weight: normal;
    margin-top: 15px;
}

.help-page .help-section-title {
    text-decoration: underline;
    font-size: 1.2em;
    text-align: left;
    font-weight: normal;
}

</style>