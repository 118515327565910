const translations = {
    es: {
        pattern: 'patrón',
        material: 'material',
        shop: 'tienda',
        identity: 'identidad',
        color: 'color',
        effect: 'efecto'
    }
}

export default translations