<template>
  <div class="search-bar">
    <div class="search-bar-wrapper">
      <div class="search-form category" v-if="withCategory">
        <select
          name="categories"
          id="categories"
          @change="addCategory($event.target.value)"
        >
          <option value="" disabled selected>Categoría ↓</option>
          <option
            :value="category"
            v-for="category in categories"
            :key="category"
            @click="reportClick(category)"
          >
            {{ category }}
          </option>
        </select>
      </div>
      <div class="search-form words">
        <div class="labels">
          <LabelComponent
            v-for="label in labels"
            :key="label.name"
            :filterName="label.filter_name"
            :name="label.name"
            :value="label.value"
            :text-color="label.textColor"
            :background-color="label.backgroundColor"
            @click="removeLabel(label.filter_name, label.value)"
          ></LabelComponent>
        </div>
        <input
          type="text"
          id="search-input"
          placeholder=""
          @keyup.enter="search"
          name="search"
          v-model="inputValue"
          list="search-suggestions"
          ref="searchInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LabelComponent from '@/components/marketplace/LabelComponent.vue'
import { removeKeyValue } from '@/utils/dictionaries'
import { getColorInfo } from '@/utils/color'
import axios from 'axios'
import { useToast, POSITION } from 'vue-toastification'

export default {
  name: 'SearchBarComponent',
  components: { LabelComponent },
  props: ['value', 'withCategory'],
  data() {
    return {
      initialPlaceholder: 'Escribe cualquier cosa y presiona enter ↵',
      inputValue: '',
      color: '',
      showSuggestions: true,
      labels: [],
      categories: [],
      filters: {},

      loadingProducts: false
    }
  },
  created() {
    this.$watch('$route.query', this.handleQueryChange)
  },
  mounted() {
    // Animate the placeholder as if someone were typing
    this.animatePlaceholder()
    this.loadCategories()
    this.addLabelsFromQuery()
  },
  methods: {
    reportClick(name) {
      this.$gtag.event('click', {
        event_category: 'search',
        event_label: name
      })
    },
    addCategory(name) {
      this.$emit('searchingProducts')
      this.$router.replace({ path: '/shop', query: { category: name } })
    },
    async loadCategories() {
      var url_get_categories = process.env.VUE_APP_BASE_URL + '/categories'
      try {
        const response = await axios.get(url_get_categories)
        this.categories = response.data
      } catch (error) {
        console.error(error)
      }
    },
    removeLabel(filterName, value) {
      var params = JSON.parse(JSON.stringify(this.$route.query))

      if (this.labels.length < 2) {
        const toast = useToast()
        toast.info('Al menos un tag debería estar seleccionado', {
          icon: {
            iconClass: 'material-symbols-rounded',
            iconChildren: 'robot_2',
            iconTag: 'span'
          },
          toastClassName: 'toast-ai',
          position: POSITION.TOP_RIGHT
        })
      } else {
        const newParams = removeKeyValue(params, filterName, value)
        this.$router.replace({ path: this.$route.path, query: newParams })
      }
    },
    async addLabelsFromQuery() {
      this.labels = []
      var params = this.$route.query
      var allowed_keys = [
        'category',
        'style',
        'pattern',
        'long',
        'fit',
        'sleeve',
        'effect',
        'shop',
        'identity',
        'details',
        'waist',
        'color'
      ]

      for (let key in params) {
        if (!allowed_keys.includes(key)) {
          break
        }
        if (Array.isArray(params[key])) {
          for (const paramsElement of params[key]) {
            var paramsForLabel = {}
            if (key == 'color') {
              const colorInfo = await getColorInfo(paramsElement)
              paramsForLabel = {
                name: colorInfo.name,
                textColor: colorInfo.contrast,
                backgroundColor: '#' + paramsElement
              }
            }
            paramsForLabel = Object.assign(
              {},
              {
                filter_name: key,
                value: paramsElement,
                name: paramsElement
              },
              paramsForLabel
            )
            this.labels.push(paramsForLabel)
          }
        } else {
          paramsForLabel = {}
          var paramsElement = params[key]
          if (key == 'color') {
            const colorInfo = await getColorInfo(paramsElement)
            paramsForLabel = {
              name: colorInfo.name,
              textColor: colorInfo.contrast,
              backgroundColor: '#' + paramsElement
            }
          }
          paramsForLabel = Object.assign(
            {},
            {
              filter_name: key,
              name: paramsElement,
              value: paramsElement
            },
            paramsForLabel
          )
          this.labels.push(paramsForLabel)
        }
      }
    },
    handleQueryChange() {
      this.addLabelsFromQuery()
    },
    async search() {
      this.loadingProducts = true
      var inputValueCopy = this.inputValue
      this.inputValue = ''
      this.$emit('searchingProducts')

      const url = process.env.VUE_APP_SEARCH_BASE_URL + '/'

      try {
        var filters = this.$route.query
        if (inputValueCopy) {
          const response = await axios.post(
            url,
            {
              prompt: inputValueCopy
            },
            {
              withCredentials: false
            }
          )
          filters = response.data.filters
          const message = response.data.message
          const toast = useToast()
          toast.info(message, {
            icon: {
              iconClass: 'material-symbols-rounded',
              iconChildren: 'robot_2',
              iconTag: 'span'
            },
            toastClassName: 'toast-ai',
            position: POSITION.TOP_RIGHT
          })
        }
        this.loadingProducts = false
        this.$router.replace({ path: '/shop', query: filters })
      } catch (error) {
        console.error(error)
      }
      this.loadingProducts = false
    },
    animatePlaceholder() {
      const placeholderText = this.initialPlaceholder
      const inputElement = document.querySelector('#search-input')
      let i = 0

      const animate = () => {
        inputElement.setAttribute(
          'placeholder',
          placeholderText.substring(0, i)
        )
        i++

        if (i <= placeholderText.length) {
          setTimeout(animate, 100)
        }
      }

      animate()
    }
  }
}
</script>

<style scoped>
.search-bar {
  width: 100%;
}

.search-bar .search-bar-wrapper {
  display: flex;
}

.search-bar .search-form {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border: solid 1px var(--background-dark-gray);
  background-color: #f8f8f8;
  border-radius: 15px !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.search-form.category {
  flex: 0 0 30%;
  font-size: 0.9em;
  margin-right: 2%;
}

.search-form.category #categories {
  width: 100%;
}

.search-form.words {
  display: flex;
  flex: 0 0 70%;
  align-items: center;
  flex-wrap: wrap;
}

.search-bar .labels {
  max-width: 50%;
  padding-right: 10px;
  padding-left: 5px;
  /*width: max-content;*/
  overflow-x: auto; /* enable horizontal scrolling when content overflows */
}

.search-bar .search-form:focus {
  border-bottom: solid 1px #000000;
  border: solid 1px #000000;
  padding: 5px;
  border-radius: 10px;
}

.search-bar #search-input {
  min-width: 40%;
  flex-grow: 1;
  background: none;
  border: none;
  margin-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.9em;
}

.search-bar #search-input:focus {
  outline: none;
}

.search-bar #search-input:hover {
  border-color: #000;
}

/* CSS animation for placeholder text */
.search-bar #search-input::placeholder {
  opacity: 0.5;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    opacity: 1;
  }
}

.suggestion-box ul li {
  display: inline-block;
}

::-webkit-scrollbar {
  display: none;
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Add a darker color to the scrollbar thumb */
::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: #bbb;
}

/* On hover, add a lighter color to the scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.search-bar select {
  background: none;
  border: none;
  padding-left: 8px;
  padding-right: 25px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #333;
}

.search-bar select::-ms-expand {
  display: none;
}

.search-bar select option {
  background-color: #ffffff;
  color: #333;
}

.search-bar select:hover {
  border-color: #000;
}

#categories {
  outline: none;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #333333;
  opacity: 1; /* Firefox */
}

@media (max-width: 768px) {
  .search-form.category {
    width: 100%;
    margin-bottom: 20px;
  }

  .search-form.words {
    width: 100%;
    margin-top: 20px;
  }

  .search-form.words .labels {
    width: 100%;
    padding-bottom: 5px;
  }

  .search-form.words #search-input {
    width: 100%;
    padding-top: 5px;
    border-top: solid #d0d0d050 1px;
  }

  .search-bar .search-bar-wrapper {
    display: block;
  }
}

.search-bar-subtitle {
  color: #ffffffdd;
  font-size: 0.9em;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-bar-subtitle img {
  margin-left: 7px;
  height: 20px;
  filter: invert(100%) opacity(80%);
}

.loading {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;
}

.loading img {
  height: 120px;
}
</style>
