<template>
  <div class="product-component">
    <div
      class="product-card"
      @click="
        this.$gtag.event('click', {
          event_category: 'marketplace',
          event_label: 'category',
          value: this.title
        })
      "
    >
      <span style="display: none">{{ product_id }}</span>
      <div class="product-image">
        <div class="banner" v-if="showBanner && identityMessage">
          {{ identityMessage }}
        </div>
        <Carousel v-bind="settings" :wrap-around="true">
          <Slide v-for="image in images" :key="image">
            <div class="carousel__item">
              <img :src="image" @load="onImgLoad" :alt="'producto '+this.title.toLowerCase()" @click="goToUrl" loading="lazy"/>
            </div>
          </Slide>
          <template #addons v-if="images.length > 1">
            <Navigation />
            <Pagination />
          </template>
        </Carousel>
      </div>
      <div class="tags-top">
        <div
          v-for="identity in identityList"
          :key="identity"
          @mouseover="showBanner = true"
          @mouseout="showBanner = false"
        >
          <div
            class="tag"
            v-if="identity == 'ecological' || identity == 'second-hand'"
          >
            <img src="leaf.png" alt="icono para los productos con identidad ecológica" height="80px" loading="lazy" />
          </div>
          <div
            class="tag"
            v-if="identity == 'social'"
            @mouseover="showBanner = true"
            @mouseout="showBanner = false"
          >
            <img src="hands.png" alt="icono para los productos con identidad social" height="80px" loading="lazy"/>
          </div>
        </div>
      </div>
      <div class="buttons-bottom"></div>
      <div class="product-info" @click="goToUrl">
        <div class="product-name text-serif">
          <a :href="this.url">{{ title }}</a>
        </div>
        <div class="product-shop text-sans-serif">vendido por {{ shop }}</div>
        <div class="product-price text-sans-serif">
          {{
            price.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'

export default {
  name: 'ProductComponent',
  props: [
    'product_id',
    'title',
    'images',
    'price',
    'shop',
    'url',
    'identityList',
    'shopInfo'
  ],
  components: {
    Carousel,
    Navigation,
    Pagination,
    Slide
  },
  data() {
    return {
      settings: {
        itemsToShow: 1,
        snapAlign: 'center'
      },
      identityMessage: '',
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {},
      showBanner: false,
      imageWasLoaded: false,
      isFavorite: false
    }
  },
  mounted() {
    this.identityMessage = this.shopInfo?.[0]?.identity ?? null
  },
  methods: {
    goToUrl: function () {
      window.open(this.url)
    },
    onImgLoad: function () {
      this.imageWasLoaded = true
    }
  }
}
</script>

<style scoped>
.product-component {
  position: relative;
}

.product-component .product-card:hover {
  cursor: pointer;
}

.product-component .product-card {
  border-right: solid 1px #eaeaea;
  border-bottom: solid 1px #f0f0f0;
}

.product-component .product-image {
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
}

.product-component .product-info {
  padding: 10px 10px 8px 20px;
}

.product-component .product-card img {
  width: 100%;
  height: 420px;
  object-fit: cover;
  object-position: top;
  transition: transform 0.3s ease-in-out;
}

.product-component .product-shop {
  font-size: 0.8em;
  text-align: left;
  font-family: 'Inter', sans-serif;
  font-weight: lighter;
  color: #707070;
}

.product-component .product-name {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.95em;
  height: 1.4em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-component .product-name a {
  text-decoration: none;
  color: #404040;
  font-weight: lighter;
  font-family: 'Inter', sans-serif;
}

.product-component .product-price {
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 1.2em;
  text-align: left;
  color: #555;
  font-family: 'Barlow Condensed', sans-serif;
}

.product-component .tags-top {
  position: absolute;
  top: 7px;
  right: 7px;
  height: 40px;
}

.product-component .tag {
  height: 40px;
  width: 40px;
  position: relative;
  background-color: #fcfcfc;
  border: 1px solid var(--background-dark-gray);
  border-radius: 30px;
  display: flex;
}

.product-component .tag img {
  height: 25px;
  position: absolute;
  width: 25px;
  top: 5px;
  left: 7px;
  filter: invert(10%) opacity(80%);
}

.product-component .buttons-bottom {
  position: absolute;
  bottom: 2px;
  right: 7px;
  height: 40px;
}

.product-component .button {
  height: 30px;
  width: 30px;
  background-color: #fcfcfc80;
  padding-top: 5px;
  border-radius: 20px;
  margin-bottom: 5px;
}

.product-component .button img {
  height: 17px;
  width: 17px;
  filter: invert(10%) opacity(40%);
}

.product-component .button:hover img {
  filter: invert(60%);
}

.product-component .button:active img {
  filter: invert(0%);
}

.product-component .banner {
  position: absolute;
  padding: 15px 15px;
  border-radius: 5px;
  top: 55px;
  right: 5px;
  left: 5px;
  text-align: justify;
  font-size: 0.9em;
  color: #300000;
  z-index: 4;
  background-color: #ffffffe0;
  animation: fadeIn 0.3s ease-in-out forwards;
}

.carousel__item {
  height: 100%;
  background-color: whitesmoke;
  color: var(--vc-clr-white);
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__item img {
  width: 100%;
  display: flex;
  align-items: center;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

@keyframes fadeIn {
  from {
    opacity: 0; /* Start with opacity 0 */
  }
  to {
    opacity: 1; /* End with opacity 1 */
  }
}
</style>
