<template>
  <div class="vertical-category-menu">
    <div class="vertical-category-menu-content">
      <ul :class="{ 'menu-open': isMenuOpen }">
        <li v-for="(category, index) in listCategories" :key="index">
          <div
            class="category"
            @click="
              this.$router.push({ name: 'shop', query: { category: category } })
            "
          >
            {{ category }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      isMenuOpen: false,
      listCategories: []
    }
  },
  mounted() {
    /**
     * Get categories from API and set them to listCategories
     */
    var url_get_categories = process.env.VUE_APP_BASE_URL + '/categories'
    axios
      .get(url_get_categories)
      .then(
        (response) => {
          this.listCategories = response.data
        },
        (error) => {
          console.error(error)
        }
      )
      .catch((error) => {
        console.error(error)
      })
  }
}
</script>

<style scoped>
.vertical-category-menu {
  overflow: hidden;
  border-bottom: 1px solid var(--background-dark-gray);
}

.vertical-category-menu-content {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none; /* Hide scrollbar on IE and Edge */
  scrollbar-width: none; /* Hide scrollbar on Firefox */

  /* Hide scrollbar on Chrome, Safari, and Opera */

  &::-webkit-scrollbar {
    display: none;
    background: transparent;
    visibility: hidden;
  }
}

/* Hide the native scrollbar */
.vertical-category-menu-content::-webkit-scrollbar {
  width: 0;
  background: transparent;
  visibility: hidden;
  display: none;
}

/* Optionally style a custom scrollbar if desired */
.vertical-category-menu-content::-webkit-scrollbar-thumb {
  display: none;
}

.vertical-category-menu-content::-webkit-scrollbar-thumb:hover {
  background: #555;
  display: none;
}

.vertical-category-menu-content::-webkit-scrollbar-track {
  background: transparent;
}

.vertical-category-menu ul li {
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: bold;
}

@media (min-width: 768px) {
  .toggle-vertical-menu {
    display: none;
  }

  ul {
    display: block;
  }

  ul li {
    display: inline-block;
    color: black !important;
    padding: 2px 10px;
  }

  ul li a {
    text-decoration: none;
    color: inherit;
  }
}

@media (max-width: 768px) {
  ul {
    display: none;
  }
}

.category:hover {
  cursor: pointer;
}
</style>
