<template>
    <div class="info-page" :style="{backgroundImage: 'url(/bbblurry-background.svg)'}">
        <SimpleHeaderComponent></SimpleHeaderComponent>
        <div class="info-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import SimpleHeaderComponent from "@/components/general/SimpleHeaderComponent.vue";

export default {
    name: "InfoPageTemplate",
    components: {SimpleHeaderComponent}
}
</script>

<style>
.info-page {
    background-attachment: fixed;
    background-size: auto 180%;
}

.info-content {
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 10%;
}

.info-content .title {
    font-family: 'Poppins', sans-serif;
    padding-top: 40px;
    font-size: 2em;
    text-align: left;
    padding-bottom: 10px;
    font-weight: normal;
}

.info-content .subtitle {
    font-family: 'Poppins', sans-serif;

    padding-top: 10px;
    font-size: 1.5em;
    text-align: left;
    padding-bottom: 10px;
    font-weight: normal;
}

.content {
    font-weight: normal;
    text-align: justify;
}
</style>