<template>
    <div class="body">
        <SimpleHeaderComponent></SimpleHeaderComponent>
        <div class="help-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import SimpleHeaderComponent from "@/components/general/SimpleHeaderComponent.vue";

export default {
    name: "HelpPage",
    components: {SimpleHeaderComponent}
}
</script>

<style>
.help-content {
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 10%;
}

.help-content .title {
    padding-top: 10px;
    font-size: 2em;
    text-align: left;
    padding-bottom: 10px;
    font-weight: normal;
}

.help-content .subtitle {
    padding-top: 10px;
    font-size: 1.5em;
    text-align: left;
    padding-bottom: 10px;
    font-weight: normal;
}

.content {
    text-align: justify;
    font-size: 1.1em;
    font-weight: lighter;
}
</style>