<template>
    <HelpPage>
        <div>
            <div class="typeform-form">
                <div data-tf-widget="v1D37Z2l" data-tf-opacity="100" data-tf-hide-headers data-tf-iframe-props="title=PQRS" data-tf-auto-focus data-tf-medium="snippet" style="width:100%;height:500px;"></div>
            </div>
        </div>
    </HelpPage>
</template>

<script>
import HelpPage from "@/pages/help/HelpPageTemplate.vue";

export default {
    name: "PQRPage",
    components: {HelpPage}
}
</script>

<style scoped>
</style>