<template>
    <HelpPage>
        <div>
            <div class="title">Propón tu tienda favorita</div>
            <div class="content">
                <p>
                    En Close, nos apasiona ofrecer a nuestros usuarios la mejor selección de moda en línea. Queremos
                    asegurarnos de que encuentres tus marcas favoritas y descubras nuevas opciones emocionantes. Por
                    eso, te
                    invitamos a que nos ayudes a hacer de Close un destino aún mejor para la moda. ¡Queremos conocer tus
                    marcas favoritas y tenerlas en nuestra plataforma!
                </p>
                <p>
                    Entendemos que cada persona tiene gustos y preferencias únicos cuando se trata de moda. Las
                    recomendaciones de nuestros usuarios son extremadamente valiosas para nosotros, ya que nos permiten
                    descubrir marcas que podríamos haber pasado por alto. Tu conocimiento y experiencia son
                    fundamentales
                    para construir una comunidad de moda diversa y enriquecedora.
                </p>
                <p>
                    Valoramos cada propuesta de marca que recibimos. Nuestro equipo de curadores revisará cuidadosamente
                    todas las recomendaciones y evaluará si cumplen con nuestros estándares de calidad y cumplimiento
                    legal.
                    Apreciamos tu paciencia mientras realizamos este proceso, ya que queremos asegurarnos de brindar a
                    nuestros usuarios solo las mejores opciones.
                </p>
                <p>
                    Queremos agradecerte de antemano por tomarte el tiempo para proponer tus marcas favoritas. Tu
                    contribución ayuda a fortalecer la comunidad de Close y a enriquecer la experiencia de todos los
                    usuarios. ¡Juntos podemos construir una plataforma de moda excepcional!
                </p>
            </div>
            <div class="propose-button">
                <button data-tf-popup="jXdVrqKy" data-tf-opacity="100" data-tf-size="100"
                        data-tf-iframe-props="title=Proponer tienda" data-tf-transitive-search-params
                        data-tf-medium="snippet"
                        style="all:unset;font-family:Helvetica,Arial,sans-serif;display:inline-block;max-width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;background-color:#DBEB2D;color:#000;font-size:20px;border-radius:25px;padding:0 33px;font-weight:bold;height:50px;cursor:pointer;line-height:50px;text-align:center;margin:0;text-decoration:none;">
                    Proponer una tienda
                </button>
            </div>
        </div>
    </HelpPage>
</template>

<script>
import HelpPage from "@/pages/help/HelpPageTemplate.vue";

export default {
    name: "ProposeShop",
    components: {HelpPage}
}
</script>

<style scoped>
.propose-button {
    margin-top: 40px;
}
</style>