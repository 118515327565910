<template>
<div class="filter-vertical-component">
    <div class="filter-block">
        <label for="sort-dropdown">Ordenar por</label>
        <select id="sort-dropdown" @change="handleChange">
            <option value="none">- -</option>
            <option value="low-to-high">Precio: De menor a mayor</option>
            <option value="high-to-low">Precio: De mayor a menor</option>
        </select>
    </div>


</div>
</template>

<script>
export default {
    name: "FilterVerticalComponent",
    methods: {
        handleChange(event) {
            const value = event.target.value;
            this.$emit('selectedSortBy', value)
            // Do something with the selected value
        }
    }
}
</script>

<style scoped>
.filter-vertical-component {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

#sort-dropdown {
    font-size: 0.9em;
    background-color: white;
    outline: none;
    margin-left: 10px;
    border: 1px solid var(--background-dark-gray);
    padding: 5px 10px;
    border-radius: 20px;
}

#sort-dropdown:focus {
    outline: none;
}
</style>