<template>
    <InfoPageTemplate>
        <div class="join-us">
            <div class="title">Sé parte de la comunidad</div>
            <div class="content">
                <p>
                    ¿Eres un vendedor de ropa y buscas aumentar tus ventas? En Close, nos encantaría ayudarte a expandir
                    tu
                    negocio. Somos una plataforma de búsqueda de ropa en Colombia y queremos invitarte a publicar tus
                    catálogos en nuestro sitio web. Al publicar tu ropa en Close, tendrás acceso a una audiencia más
                    amplia
                    y podrás llegar a clientes que quizás no habías alcanzado antes.
                </p>
                <p>
                    En Close, creemos en la diversidad y queremos ofrecer a nuestros usuarios la mayor variedad de
                    opciones
                    de moda posible. Si tienes ropa de alta calidad y diseños únicos, nos encantaría trabajar contigo
                    para
                    ayudarte a mostrar tus productos en nuestro sitio web. Nos enfocamos en ofrecer a nuestros usuarios
                    opciones de moda variadas y adaptadas a sus preferencias, y estamos seguros de que tu ropa podría
                    ser
                    una gran adición a nuestro catálogo.
                </p>
                <p>
                    Si te interesa publicar tus catálogos en Close, no dudes en ponerte en contacto con nosotros.
                    Estamos
                    entusiasmados de trabajar contigo y ayudarte a expandir tu negocio. En Close, creemos que trabajar
                    juntos es la mejor manera de mejorar la industria de la moda en Colombia y ofrecer a nuestros
                    usuarios
                    opciones de moda de calidad. ¡Esperamos trabajar contigo pronto!
                </p>

            </div>
            <div class="advantages">
                <div class="subtitle">Por qué elegirnos?</div>
                <div class="content">
                    <ul>
                        <li>Mayor exposición: Al publicar tus catálogos en Close, tendrás la oportunidad de llegar a un
                            público más amplio que busca productos específicos en el área de moda. Esto significa una
                            mayor
                            visibilidad para tu marca y un mayor potencial de ventas.
                        </li>
                        <li>Ahorro de tiempo y esfuerzo: Al publicar tus catálogos en Close, podrás ahorrar tiempo y
                            esfuerzo en la tarea de promocionar tus productos y encontrar nuevos clientes. Close se
                            encarga
                            de promocionar tus productos y atraer a los compradores adecuados, permitiéndote
                            concentrarte en
                            otras áreas de tu negocio.
                        </li>
                        <li>Análisis de datos: Close proporciona a los vendedores herramientas de análisis de datos que
                            les
                            permiten conocer mejor a su audiencia y tomar decisiones informadas en cuanto a sus
                            productos y
                            promociones. Esto les ayuda a ajustar su estrategia de negocio y mejorar sus ventas.
                        </li>
                        <li>Fácil integración: Close está diseñado para ser fácil de usar e integrarse con otros
                            sistemas de
                            venta en línea. Esto significa que podrás cargar tus catálogos en Close sin tener que
                            realizar
                            cambios importantes en tu sitio web o en tus sistemas de gestión de inventario.
                        </li>
                        <li>Comisiones competitivas: Close ofrece comisiones competitivas para los vendedores, lo que
                            significa que podrás obtener más ganancias por tus ventas en comparación con otras
                            plataformas
                            de venta en línea.
                        </li>
                        <li>Experiencia de usuario personalizada: Close se enfoca en brindar una experiencia de usuario
                            personalizada para cada comprador, lo que significa que los vendedores pueden estar seguros
                            de
                            que sus productos llegarán a las personas adecuadas.
                        </li>
                        <li>Innovación constante: Close está comprometido con la innovación constante, lo que significa
                            que
                            los vendedores pueden esperar nuevas características y mejoras que ayuden a mejorar la
                            experiencia de venta en línea.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="advisers">
                <button data-tf-popup="zEVF6yKG" data-tf-opacity="100" data-tf-hide-headers data-tf-size="100"
                        data-tf-iframe-props="title=Únete a nosotros" data-tf-transitive-search-params
                        data-tf-medium="snippet"
                        style="all:unset;font-family:Helvetica,Arial,sans-serif;display:inline-block;max-width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;background-color:#DBEB2D;color:#000;font-size:21px;border-radius:26px;padding:0 35px;font-weight:bold;height:52.5px;cursor:pointer;line-height:52.5px;text-align:center;margin:0;text-decoration:none;">
                    Quiero recibir asesoría
                </button>
            </div>
        </div>
    </InfoPageTemplate>
</template>

<script>
import InfoPageTemplate from "@/pages/InfoPageTemplate.vue";

export default {
    name: "AboutUsPage",
    components: {InfoPageTemplate}
}
</script>

<style scoped>
.advisers {
    margin-top: 30px;
    margin-bottom: 70px;
}

.about-us .button {
    margin: auto;
    margin-top: 60px;
    padding: 10px 20px;
    width: 230px;
    border-radius: 10px;
    background-color: var(--background-gray);
}

.about-us .button:hover {
    cursor: pointer;
    background-color: var(--background-dark-gray);
}
</style>