export function getProductFilters(products) {

    var keys = ['style', 'pattern', 'long', 'fit', 'sleeve', 'shop', 'identity', 'details', 'waist', 'effect']

    var productFilters = {
        'style': new Array(),
        'pattern': new Array(),
        'long': new Array(),
        'fit': new Array(),
        'sleeve': new Array(),
        'effect': new Array(),
        'shop': new Array(),
        'identity': new Array(),
        'details': new Array(),
        'waist': new Array(),
    }

    for (const product of products) {
        for (const keyElement of keys) {
            if (keyElement in product && product[keyElement]) {
                if (keyElement == 'identity'){
                    for (const keyElementItem of product[keyElement]){
                        if (!(productFilters[keyElement].includes(keyElementItem))) {
                            productFilters[keyElement].push(keyElementItem)
                        }
                    }
                }
                else {
                    if (!(productFilters[keyElement].includes(product[keyElement]))) {
                        productFilters[keyElement].push(product[keyElement])
                    }
                }
            }
        }
    }

    return productFilters;
}
