<template>
    <div class="label">
        #{{ name }}
    </div>
</template>

<script>
export default {
    name: "LabelComponent",
    props: {
        'name': String,
        'filterName': String,
        'backgroundColor': {
            type: String,
            default: '#ededed'
        },
        'textColor': {
            type: String,
            default: '#101010'
        }
    }
}
</script>

<style scoped>
.label {
    font-family: 'Inter', sans-serif;
    font-weight: lighter;
    font-size: 0.8em;
    margin-right: 8px;
    border-radius: 10px;
    padding-top: 3px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 8px;
    display: inline-block;
    background-color: v-bind(backgroundColor);
    color: v-bind(textColor) !important;
    text-transform: lowercase;
    animation: fade-in 3s ease-in-out;
}

.label:hover {
    cursor: pointer;
    background-color: #dfdfdf;
    color: #FFFFFF;
}

@keyframes fadeIn {
    from {
        opacity: 0; /* Start with opacity 0 */
    }
    to {
        opacity: 1; /* End with opacity 1 */
    }
}
</style>
