<template>
    <LegalPageTemplate>
        <div class="terms-and-conditions">
            <div class="title">Términos y condiciones</div>
            <div class="content">
                <h4>Actualizado al 20 de marzo de 2023</h4>
                <h3>Términos Generales</h3>
                Al acceder y realizar un pedido en close, confirmas que estás de acuerdo y sujeto a los términos de
                servicio
                contenidos en los Términos y Condiciones descritos a continuación. Estos términos se aplican a todo el
                sitio
                web y cualquier correo electrónico u otro tipo de comunicación entre tú y close.

                En ningún caso será responsable el equipo de close por daños directos, indirectos, especiales,
                incidentales
                o consecuentes, incluyendo, entre otros, la pérdida de datos o ganancias, derivados del uso o la
                imposibilidad de uso de los materiales en este sitio, incluso si close o un representante autorizado ha
                sido
                informado de la posibilidad de tales daños. Si el uso de materiales de este sitio resulta en la
                necesidad de
                servicios, reparación o corrección de equipos o datos, asumes cualquier costo asociado.

                close no será responsable por ningún resultado que pueda ocurrir durante el uso de nuestros recursos.
                Nos
                reservamos el derecho de cambiar los precios y revisar la política de uso de recursos en cualquier
                momento.

                <h3>Licencia</h3>
                close te otorga una licencia limitada, revocable, no exclusiva y no transferible para descargar,
                instalar y
                usar el sitio web estrictamente de acuerdo con los términos de este acuerdo.

                Estos Términos y Condiciones son un contrato entre tú y close (referido en estos Términos y Condiciones
                como
                "close", "nosotros", "nos" o "nuestro"), el proveedor del sitio web close y los servicios accesibles
                desde
                el sitio web close (que se denominan colectivamente en estos Términos y Condiciones como el "Servicio
                close").

                Al utilizar el Servicio close, aceptas quedar sujeto a estos Términos y Condiciones. Si no estás de
                acuerdo
                con estos Términos y Condiciones, por favor, no uses el Servicio close. En estos Términos y Condiciones,
                "tú" se refiere tanto a ti como individuo como a la entidad que representas. Si violas cualquiera de
                estos
                Términos y Condiciones, nos reservamos el derecho de cancelar tu cuenta o bloquear el acceso a tu cuenta
                sin
                previo aviso.


                <h3>Meanings</h3>

                For this Terms & Conditions:

                <ul>
                    <li>
                        Cookie: pequeña cantidad de datos generados por un sitio web y guardados por tu navegador web.
                        Se
                        utiliza para identificar tu navegador, proporcionar análisis y recordar información sobre ti,
                        como
                        tus preferencias de idioma o información de inicio de sesión.
                    </li>
                    <li>
                        Empresa: cuando esta política menciona "Empresa", "nosotros", "nos" o "nuestro", se refiere a
                        close,
                        (Calle 93 #11-28), que es responsable de tu información bajo estos Términos y Condiciones.
                    </li>
                    <li>
                        País: donde close o los propietarios/fundadores de close tienen su base, en este caso es
                        Colombia.
                    </li>
                    <li>
                        Dispositivo: cualquier dispositivo conectado a internet, como un teléfono, tableta, computadora
                        o
                        cualquier otro dispositivo que se pueda usar para visitar close y usar los servicios.
                    </li>
                    <li>
                        Servicio: se refiere al servicio proporcionado por close según se describe en los términos
                        relativos
                        (si están disponibles) y en esta plataforma.
                    </li>
                    <li>
                        Terceros: se refiere a los anunciantes, patrocinadores de concursos, socios promocionales y de
                        marketing, y otros que proporcionan nuestro contenido o cuyos productos o servicios creemos que
                        pueden ser de su interés.
                    </li>
                    <li>
                        Sitio web: el sitio close. Al cual se puede acceder a través de esta URL: www.close.com.co
                    </li>
                    <li>
                        Usted: una persona o entidad que está registrada en close para utilizar los Servicios.
                    </li>
                </ul>


                <h3>Restricciones</h3>

                Usted acepta no, y no permitirá a otros:

                <ul>
                    <li>
                        Licenciar, vender, alquilar, arrendar, asignar, distribuir, transmitir, alojar, subcontratar,
                        divulgar o comercializar de otro modo el sitio web o hacer la plataforma disponible a cualquier
                        tercero.

                    </li>
                    <li>
                        Modificar, hacer trabajos derivados, desmontar, descifrar, compilar o ingeniería inversa de
                        cualquier parte del sitio web.

                    </li>
                    <li>
                        Eliminar, alterar u oscurecer cualquier aviso de propiedad (incluido cualquier aviso de
                        copyright o
                        marca comercial) de close o sus afiliados, socios, proveedores o licenciantes del sitio web.

                    </li>
                </ul>

                <h3>Política de devoluciones y reembolsos</h3>

                Gracias por comprar en close. Apreciamos el hecho de que le guste comprar los productos que construimos.
                También queremos asegurarnos de que tenga una experiencia gratificante mientras explora, evalúa y compra
                nuestros productos.

                <p>
                    Como en cualquier experiencia de compra, existen términos y condiciones que se aplican a las
                    transacciones
                    en close. Seremos breves en la medida en que nuestros abogados lo permitan. Lo principal a recordar
                    es
                    que
                    al realizar un pedido o una compra en close, acepta los términos junto con la Política de privacidad
                    de
                    close.

                </p>

                Si, por alguna razón, no está completamente satisfecho con algún bien o servicio que proporcionamos, no
                dude
                en ponerse en contacto con nosotros y discutiremos cualquier problema que esté experimentando con
                nuestro
                producto.

                <h3>Tus Sugerencias</h3>
                Cualquier comentario, idea, mejora o sugerencia (colectivamente, "Sugerencias") proporcionada por ti a
                close
                con respecto al sitio web será propiedad exclusiva de close.

                close podrá utilizar, copiar, modificar, publicar o redistribuir las Sugerencias para cualquier
                propósito y
                de cualquier manera sin dar crédito o compensación alguna a ti.

                <h3>Tu Consentimiento</h3>

                Hemos actualizado nuestros Términos y Condiciones para brindarte completa transparencia sobre lo que se
                establece cuando visitas nuestro sitio y cómo se utiliza. Al utilizar nuestro sitio web, registrarte en
                una
                cuenta o realizar una compra, das tu consentimiento a nuestros Términos y Condiciones.

                <h3>Enlaces a Otros Sitios Web</h3>

                Estos Términos y Condiciones se aplican solo a los Servicios. Los Servicios pueden contener enlaces a
                otros
                sitios web que no son operados ni controlados por close. No somos responsables del contenido, la
                exactitud o
                las opiniones expresadas en dichos sitios web, y dichos sitios web no son investigados, monitoreados o
                verificados por nosotros en cuanto a su exactitud o integridad. Recuerda que cuando utilizas un enlace
                para
                ir de los Servicios a otro sitio web, nuestros Términos y Condiciones ya no están en efecto. Tu
                navegación e
                interacción en cualquier otro sitio web, incluidos aquellos que tienen un enlace en nuestra plataforma,
                están sujetos a las propias reglas y políticas de ese sitio web. Dichos terceros pueden utilizar sus
                propias
                cookies u otros métodos para recopilar información sobre ti.


                <h3>Cookies</h3>
                close utiliza "Cookies" para identificar las áreas de nuestro sitio web que ha visitado. Una cookie es
                una
                pequeña
                pieza de datos almacenados en su computadora o dispositivo móvil por su navegador web. Usamos Cookies
                para
                mejorar el
                rendimiento y la funcionalidad de nuestro sitio web, pero no son esenciales para su uso. Sin embargo,
                sin
                estas
                cookies, ciertas funcionalidades como los videos pueden estar no disponibles o se le pediría que ingrese
                sus
                detalles de inicio de sesión cada vez que visite el sitio web ya que no podríamos recordar que había
                iniciado
                sesión anteriormente. La mayoría de los navegadores web se pueden configurar para desactivar el uso de
                Cookies. Sin
                embargo, si desactiva las Cookies, es posible que no pueda acceder a la funcionalidad de nuestro sitio
                web
                correctamente o en absoluto. Nunca colocamos información de identificación personal en las Cookies.

                <h3>Cambios en nuestros Términos y Condiciones</h3>

                Usted reconoce y acepta que close puede dejar de proporcionar el Servicio (o cualquier función dentro
                del
                Servicio) a usted o a los usuarios en general, de manera permanente o temporal, a discreción exclusiva
                de
                close, sin previo aviso a usted. Usted puede dejar de usar el Servicio en cualquier momento. No es
                necesario
                informar específicamente a close cuando deje de usar el Servicio. Usted reconoce y acepta que si close
                desactiva el acceso a su cuenta, es posible que no pueda acceder al Servicio, a los detalles de su
                cuenta o
                a cualquier archivo u otro material que se encuentre en su cuenta.

                Si decidimos cambiar nuestros Términos y Condiciones, publicaremos esos cambios en esta página y / o
                actualizaremos la fecha de modificación de los Términos y Condiciones a continuación.

                <h3>Modificaciones a nuestro sitio web</h3>

                close se reserva el derecho de modificar, suspender o descontinuar, temporal o permanentemente, el sitio
                web
                o cualquier servicio al que se conecte, con o sin previo aviso y sin responsabilidad hacia usted.


                <h3>Actualizaciones en nuestro sitio web</h3>
                De vez en cuando, Close puede proporcionar mejoras o mejoras en las funciones / funcionalidades del
                sitio
                web, que pueden incluir parches, correcciones de errores, actualizaciones, mejoras y otras
                modificaciones
                ("Actualizaciones").

                <p>
                    Las actualizaciones pueden modificar o eliminar ciertas características y / o funcionalidades del
                    sitio
                    web. Usted acepta que Close no tiene la obligación de (i) proporcionar ninguna Actualización, o (ii)
                    continuar proporcionando o habilitando características y / o funcionalidades particulares del sitio
                    web
                    para usted.

                </p>

                Además, acepta que todas las Actualizaciones serán (i) consideradas como una parte integral del sitio
                web, y
                (ii) estarán sujetas a los términos y condiciones de este Acuerdo.

                <h3>Servicios de terceros</h3>
                Podemos mostrar, incluir o poner a disposición contenido de terceros (incluidos datos, información,
                aplicaciones y otros productos servicios) o proporcionar enlaces a sitios web o servicios de terceros
                ("Servicios de terceros").

                <p>
                    Usted reconoce y acepta que Close no será responsable de ningún Servicio de terceros, incluida su
                    precisión, integridad, puntualidad, validez, cumplimiento de derechos de autor, legalidad, decencia,
                    calidad o cualquier otro aspecto. Close no asume y no tendrá ninguna responsabilidad o
                    responsabilidad
                    ante usted o cualquier otra persona o entidad por ningún Servicio de terceros.

                </p>

                Los Servicios de terceros y los enlaces a los mismos se proporcionan únicamente como una conveniencia
                para
                usted y usted los accede y utiliza completamente bajo su propio riesgo y sujeto a los términos y
                condiciones
                de dichos terceros.


                <h3>Término y Terminación</h3>
                Este Acuerdo permanecerá en vigencia hasta que sea terminado por usted o por Close.

                Close puede, a su sola discreción, en cualquier momento y por cualquier motivo o sin razón alguna,
                suspender
                o terminar este Acuerdo con o sin previo aviso.

                Este Acuerdo terminará de inmediato, sin previo aviso de Close, en caso de que usted no cumpla con
                cualquier
                disposición de este Acuerdo. Usted también puede terminar este Acuerdo eliminando el sitio web y todas
                las
                copias del mismo de su computadora.

                Al finalizar este Acuerdo, usted deberá cesar todo uso del sitio web y eliminar todas las copias del
                sitio
                web de su computadora. La terminación de este Acuerdo no limitará ninguno de los derechos o remedios de
                Close en caso de incumplimiento por su parte (durante el término de este Acuerdo) de cualquiera de sus
                obligaciones bajo el presente Acuerdo.

                <h3>Aviso de Infracción de Derechos de Autor</h3>
                Si usted es propietario de derechos de autor o agente de dicho propietario y cree que cualquier material
                en
                nuestro sitio web constituye una infracción de sus derechos de autor, contáctenos proporcionando la
                siguiente información: (a) una firma física o electrónica del propietario de derechos de autor o una
                persona
                autorizada para actuar en su nombre; (b) identificación del material que se afirma que infringe; (c) su
                información de contacto, incluyendo su dirección, número de teléfono y correo electrónico; (d) una
                declaración de usted de que tiene una creencia de buena fe de que el uso del material no está autorizado
                por
                los propietarios de derechos de autor; y (e) una declaración de que la información en la notificación es
                precisa y, bajo pena de perjurio, está autorizado a actuar en nombre del propietario.

                <h3>Indemnización</h3>
                Usted acepta indemnizar y mantener indemne a Close y a sus empresas matrices, subsidiarias, afiliadas,
                funcionarios, empleados, agentes, socios y licenciantes (si los hubiere) de cualquier reclamo o demanda,
                incluyendo honorarios razonables de abogados, debido a o derivados de: (a) su uso del sitio web; (b) su
                violación de este Acuerdo o cualquier ley o regulación; o (c) su violación de cualquier derecho de un
                tercero.

                <h3>Sin garantías</h3>
                El sitio web se proporciona "TAL CUAL" y "SEGÚN DISPONIBILIDAD", y con todos los defectos y errores sin
                garantía de ningún tipo. En la medida máxima permitida por la ley aplicable, close, en su propio nombre
                y en
                nombre de sus afiliados y respectivos licenciatarios y proveedores de servicios, renuncia expresamente a
                todas las garantías, ya sean expresas, implícitas, legales o de otro tipo, con respecto al sitio web,
                incluyendo todas las garantías implícitas de comerciabilidad, aptitud para un propósito particular,
                título y
                no infracción, y garantías que puedan surgir en el curso de la negociación, curso del desempeño, uso o
                práctica comercial. Sin limitación a lo anterior, close no proporciona ninguna garantía o compromiso, y
                no
                hace ninguna representación de ningún tipo de que el sitio web cumplirá con sus requisitos, logrará
                algún
                resultado previsto, será compatible o funcionará con cualquier otro software, sistemas o servicios,
                operará
                sin interrupción, cumplirá con algún estándar de rendimiento o confiabilidad o será libre de errores o
                que
                cualquier error o defecto pueda o será corregido.

                Sin limitar lo anterior, ni close ni ningún proveedor de close hace ninguna representación o garantía de
                ningún tipo, expresa o implícita: (i) en cuanto al funcionamiento o disponibilidad del sitio web, o la
                información, contenido y materiales o productos incluidos en él; (ii) que el sitio web será
                ininterrumpido o
                libre de errores; (iii) en cuanto a la exactitud, confiabilidad o actualidad de cualquier información o
                contenido proporcionado a través del sitio web; o (iv) que el sitio web, sus servidores, el contenido o
                los
                correos electrónicos enviados por o en nombre de close están libres de virus, scripts, caballos de
                Troya,
                gusanos, malware, bombas de tiempo u otros componentes perjudiciales.

                Algunas jurisdicciones no permiten la exclusión o limitación de las garantías implícitas o las
                limitaciones
                de los derechos estatutarios aplicables a un consumidor, por lo que algunas o todas las exclusiones y
                limitaciones anteriores pueden no aplicarse a usted.

                <h3>Limitación de responsabilidad</h3>
                Sin perjuicio de cualquier daño que pueda sufrir, la responsabilidad total de close y cualquiera de sus
                proveedores en virtud de cualquier disposición de este Acuerdo y su único recurso para todo lo anterior
                se
                limitará al monto realmente pagado por usted por el sitio web.


                <p>
                    En la medida máxima permitida por la ley aplicable, en ningún caso close o sus proveedores serán
                    responsables por daños especiales, incidentales, indirectos o consecuentes de ningún tipo
                    (incluyendo,
                    pero
                    no limitándose a, daños por pérdida de beneficios, pérdida de datos u otra información, interrupción
                    del
                    negocio, lesiones personales, pérdida de privacidad que surja de o esté relacionada de alguna manera
                    con
                    el
                    uso o la incapacidad de usar el sitio web, software de terceros y/o hardware de terceros utilizado
                    con
                    el
                    sitio web, o de otra manera en relación con cualquier disposición de este Acuerdo), incluso si close
                    o
                    algún
                    proveedor ha sido informado de la posibilidad de tales daños y aunque el remedio falle en su
                    propósito
                    esencial.
                </p>

                Algunos estados/jurisdicciones no permiten la exclusión o limitación de daños incidentales o
                consecuentes,
                por lo que la limitación o exclusión anterior puede no aplicarse a usted.

                <h3>Divisibilidad</h3>
                <p>
                    Si alguna disposición de este Acuerdo se considera inaplicable o inválida, dicha disposición se
                    cambiará
                    e
                    interpretará para cumplir con los objetivos de dicha disposición en la mayor medida posible según la
                    ley
                    aplicable y las disposiciones restantes continuarán en plena vigencia y efecto.

                </p>
                Este Acuerdo, junto con la Política de privacidad y cualquier otro aviso legal publicado por close en
                los
                Servicios, constituirá el acuerdo completo entre usted y close con respecto a los Servicios. Si alguna
                disposición de este Acuerdo es considerada inválida por un tribunal de jurisdicción competente, la
                invalidez
                de dicha disposición no afectará la validez de las disposiciones restantes de este Acuerdo, que
                continuarán
                en pleno vigor y efecto. La falta de aplicación por parte de close de cualquier derecho o disposición en
                virtud de este Acuerdo no se considerará una renuncia a tal derecho o disposición. USTED Y close
                ACUERDAN
                QUE CUALQUIER CAUSA DE ACCIÓN QUE SURJA DE O ESTÉ RELACIONADA CON LOS SERVICIOS DEBE COMENZAR DENTRO DE
                UN
                (1) AÑO DESPUÉS DE QUE SURJA LA CAUSA DE ACCIÓN. DE LO CONTRARIO, DICHA CAUSA DE ACCIÓN ESTARÁ
                PERMANENTEMENTE PROHIBIDA.

                <h3>Renuncia</h3>
                <p>
                    Salvo lo dispuesto en este documento, el hecho de no ejercer un derecho o no exigir el cumplimiento
                    de
                    una
                    obligación en virtud de este Acuerdo no afectará la capacidad de una parte para ejercer dicho
                    derecho o
                    exigir
                    dicho cumplimiento en cualquier momento posterior, ni constituirá una renuncia a una infracción de
                    cualquier
                    infracción posterior.
                </p>


                Ninguna falta de ejercicio, y ninguna demora en el ejercicio, por parte de cualquiera de las partes, de
                cualquier
                derecho o poder en virtud de este Acuerdo operará como una renuncia a ese derecho o poder. Tampoco
                cualquier
                ejercicio único o parcial de cualquier derecho o poder en virtud de este Acuerdo precluirá un ejercicio
                posterior
                de ese derecho o de cualquier otro derecho concedido aquí. En caso de conflicto entre este Acuerdo y
                cualquier
                término de compra u otros términos aplicables, los términos de este Acuerdo prevalecerán.

                <h3>Modificaciones a este Acuerdo</h3>

                close se reserva el derecho, a su sola discreción, de modificar o reemplazar este Acuerdo en cualquier
                momento. Si
                hay una revisión sustancial, proporcionaremos al menos 30 días de aviso antes de que entren en vigor los
                nuevos términos.
                Lo que constituye un cambio sustancial será determinado a nuestra única discreción.
                Al continuar accediendo o usando nuestro sitio web después de que las revisiones entren en vigencia,
                acepta
                estar
                sujeto a los términos revisados. Si no acepta los nuevos términos, ya no está autorizado para usar
                close.

                <h3>Acuerdo completo</h3>

                El Acuerdo constituye el acuerdo completo entre usted y close con respecto a su uso del sitio web y
                reemplaza todos
                los acuerdos escritos u orales previos y contemporáneos entre usted y close. Es posible que esté sujeto
                a
                términos y
                condiciones adicionales que se aplican cuando usa o compra otros servicios de close, que close le
                proporcionará en el
                momento de dicho uso o compra.

                <h3>Actualizaciones a nuestros términos</h3>

                Podemos cambiar nuestro servicio y políticas, y podemos necesitar hacer cambios a estos términos para
                que
                reflejen
                con precisión nuestro servicio y políticas. A menos que se requiera por ley, le notificaremos (por
                ejemplo,
                a través
                de nuestro servicio) antes de hacer cambios a estos términos y le daremos la oportunidad de revisarlos
                antes
                de que
                entren en vigencia. Luego, si continúa utilizando el servicio, quedará vinculado por los términos
                actualizados. Si no
                desea aceptar estos términos o cualquier término actualizado, puede eliminar su cuenta.


                <h3>Propiedad Intelectual</h3>
                El sitio web y todo su contenido, características y funcionalidades (incluyendo, pero sin limitarse a,
                toda
                la información, software, texto, displays, imágenes, video y audio, y el diseño, selección y disposición
                del
                mismo), son propiedad de close, sus licenciantes u otros proveedores de dicho material y están
                protegidos
                por las leyes de derechos de autor, marca registrada, patente, secreto comercial y otros derechos de
                propiedad intelectual o derechos de propiedad, tanto en Colombia como internacionalmente. El material no
                puede ser copiado, modificado, reproducido, descargado o distribuido de ninguna manera, en su totalidad
                o en
                parte, sin el previo consentimiento por escrito de close, excepto en los casos expresamente permitidos
                en
                estos Términos y Condiciones. Cualquier uso no autorizado del material está prohibido.

                <h3>Acuerdo de Arbitraje</h3>
                Esta sección se aplica a cualquier disputa, EXCEPTO EN DISPUTAS RELACIONADAS CON RECLAMOS POR
                INJUNCIONES O
                EQUIDAD RESPECTO A LA APLICACIÓN O VALIDEZ DE LOS DERECHOS DE PROPIEDAD INTELECTUAL DE USTED O DE close.
                El
                término "disputa" significa cualquier disputa, acción u otra controversia entre usted y close en
                relación
                con los servicios o este acuerdo, ya sea en contrato, garantía, agravio, estatuto, regulación, ordenanza
                o
                cualquier otra base legal o equitativa. "Disputa" tendrá el significado más amplio posible permitido por
                la
                ley.

                <h3>Notificación de Disputa</h3>
                En caso de disputa, usted o close deben enviar a la otra parte una Notificación de Disputa, que es una
                declaración escrita que establece el nombre, dirección e información de contacto de la parte que la
                presenta, los hechos que dan lugar a la disputa y el alivio solicitado. Debe enviar cualquier
                Notificación
                de Disputa por correo electrónico a: pqrs@close.com.co. close enviará cualquier Notificación de Disputa
                a su dirección por correo si la tiene, o de lo contrario a su dirección de correo electrónico. Usted y
                close
                intentarán resolver cualquier disputa a través de negociación informal dentro de los sesenta (60) días a
                partir de la fecha en que se envíe la Notificación de Disputa. Después de sesenta (60) días, usted o
                close
                pueden iniciar el arbitraje.

                <h3>Arbitraje Vinculante</h3>
                Si usted y close no resuelven ninguna disputa a través de negociación informal, cualquier otro esfuerzo
                para
                resolver la disputa se llevará a cabo exclusivamente mediante arbitraje vinculante, como se describe en
                esta
                sección. Está renunciando al derecho a litigar (o participar como parte o miembro de la clase) todas las
                disputas en un tribunal ante un juez o jurado. La disputa será resuelta mediante arbitraje vinculante de
                acuerdo con las reglas de arbitraje comercial de la Asociación Americana de Arbitraje. Cualquiera de las
                partes puede solicitar cualquier medida cautelar o provisional a un tribunal de jurisdicción competente,
                según sea necesario para proteger los derechos o la propiedad de la parte pendiente de la finalización
                del
                arbitraje. Cualquier y todos los costos

                <h3>Envíos y Privacidad</h3>
                En caso de que envíe o publique cualquier idea, sugerencia creativa, diseño, fotografía, información,
                publicidad,
                datos o propuestas, incluyendo ideas para productos, servicios, características, tecnologías o
                promociones
                nuevos o
                mejorados, usted acepta expresamente que dichos envíos serán tratados automáticamente como no
                confidenciales
                y no
                propietarios y se convertirán en propiedad exclusiva de close sin compensación o crédito alguno para
                usted.
                close y
                sus afiliados no tendrán ninguna obligación con respecto a dichos envíos o publicaciones y podrán usar
                las
                ideas
                contenidas en dichos envíos o publicaciones para cualquier propósito en cualquier medio de manera
                perpetua,
                incluyendo,
                entre otros, desarrollar, fabricar y comercializar productos y servicios utilizando dichas ideas.

                <h3>Promociones</h3>
                close puede, de vez en cuando, incluir concursos, promociones, sorteos u otras actividades
                ("Promociones")
                que
                requieren que envíe material o información sobre usted mismo. Tenga en cuenta que todas las Promociones
                pueden estar
                regidas por reglas separadas que pueden contener ciertos requisitos de elegibilidad, como restricciones
                en
                cuanto a la
                edad y la ubicación geográfica. Usted es responsable de leer todas las reglas de las Promociones para
                determinar si es
                elegible para participar o no. Si participa en cualquier Promoción, acepta cumplir con todas las Reglas
                de
                Promoción.

                Se pueden aplicar términos y condiciones adicionales a las compras de bienes o servicios en o a través
                de
                los
                Servicios, los cuales se incorporan a este Acuerdo mediante esta referencia.

                <h3>Errores tipográficos</h3>
                En caso de que un producto y/o servicio esté listado a un precio incorrecto o con información incorrecta
                debido a un
                error tipográfico, tendremos el derecho de rechazar o cancelar cualquier pedido realizado para el
                producto
                y/o servicio
                listado al precio incorrecto. Tendremos el derecho de rechazar o cancelar cualquier pedido de este tipo,
                ya
                sea que el
                pedido haya sido confirmado o no y se haya cargado su tarjeta de crédito. Si se ha cargado su tarjeta de
                crédito por la
                compra y su pedido se cancela, emitiremos inmediatamente un crédito a su cuenta de tarjeta de crédito u
                otra
                cuenta de
                pago por el monto del cargo.

                <h3>Varios</h3>
                Si alguna disposición o parte de estos Términos y Condiciones es encontrada inválida o no aplicable por
                un
                tribunal competente, las disposiciones restantes continuarán en pleno vigor y efecto. Cualquier renuncia
                de
                cualquier disposición de estos Términos y Condiciones solo será efectiva si está por escrito y firmada
                por
                un representante autorizado de Close. Close puede buscar medidas cautelares u otro tipo de compensación
                equitativa, sin la obligación de presentar una fianza o garantía, en caso de incumplimiento o
                incumplimiento
                anticipado por su parte. Close opera y controla el servicio de Close desde sus oficinas en Colombia, y
                el
                Servicio no está destinado para su distribución o uso en ninguna jurisdicción o país donde dicha
                distribución o uso sea contrario a la ley o regulación. Por lo tanto, aquellos que eligen acceder al
                servicio de Close desde otras ubicaciones lo hacen por su propia iniciativa y son los únicos
                responsables
                del cumplimiento de las leyes locales, si corresponde. Estos Términos y Condiciones (incluida la
                Política de
                Privacidad de Close) contienen todo lo acordado entre usted y Close con respecto a su tema y no pueden
                ser
                modificados o cambiados por usted. Los títulos de sección utilizados en este Acuerdo son solo por
                conveniencia y no tienen ninguna significancia legal.

                <h3>Aviso legal</h3>

                Close no se hace responsable de ningún contenido, código o inexactitud. Close no proporciona ninguna
                garantía.
                En ningún caso Close será responsable por daños especiales, directos, indirectos, consecuentes o
                incidentales, o cualquier otro tipo de daños, ya sea en una acción de contrato, negligencia u otro tipo
                de
                delito, derivados del uso del servicio o del contenido del servicio. Close se reserva el derecho de
                hacer
                adiciones, eliminaciones o modificaciones al contenido del servicio en cualquier momento sin previo
                aviso.

                El servicio de Close y sus contenidos se proporcionan "tal cual" y "según disponibilidad" sin ninguna
                garantía o representación de ningún tipo, ya sea expresa o implícita. Close es un distribuidor, no un
                editor, del contenido suministrado por terceros. Como tal, Close no ejerce ningún control editorial
                sobre
                dicho contenido y no garantiza ni representa la exactitud, confiabilidad o actualidad de ninguna
                información, contenido, servicio o mercancía proporcionada a través del servicio de Close o accesible a
                través de él. Close renuncia específicamente a todas las garantías y representaciones con respecto a
                cualquier contenido transmitido en o en conexión con el servicio de Close, o en sitios que puedan
                aparecer
                como enlaces en el servicio de Close, o en los productos proporcionados como parte de, o en conexión
                con, el
                servicio de Close, incluyendo, sin limitación, cualquier garantía de comerciabilidad, idoneidad para un
                propósito particular o no infracción de los derechos de terceros. Ningún consejo oral o información
                escrita
                proporcionada por Close o cualquiera de sus afiliados, empleados, funcionarios, directores, agentes o
                similares creará una garantía. La información sobre precios y disponibilidad está sujeta a cambios sin
                previo aviso. Close no garantiza que el servicio de Close será ininterrumpido, sin errores, oportuno o
                sin
                corrupción.

                <h3>Contáctanos</h3>
                No dudes en contactarnos si tienes alguna pregunta.
                <ul>
                    <li>Vía correo electrónico: pqrs@close.com.co</li>
                </ul>

            </div>
        </div>

    </LegalPageTemplate>
</template>

<script>
import LegalPageTemplate from "@/pages/legal/LegalPageTemplate.vue";

export default {
    name: "TermsAndConditions",
    components: {LegalPageTemplate}
}
</script>

<style scoped>
</style>