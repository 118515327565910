<template>
    <div>
        <div class="products-grid" ref="products"
             :style="`grid-template-columns: repeat(${columnsInGrid}, 1fr);`">
            <ProductComponent v-for="product in this.productsInCurrentPage"
                              :key="product.product_id"
                              :shopInfo="shopsInformation.filter(shop => shop.name == product.shop)"
                              :product_id="product.product_id"
                              :title="product.title"
                              :images="product.images.slice(0,5)"
                              :price="product.price"
                              :identityList="product.identity"
                              :shop="product.shop"
                              :url="product.url"></ProductComponent>
        </div>
        <div class="pagination-wrapper" v-if="products.length">
            <div class="page-button" v-if="this.currentPage > 1"
                 @click="this.currentPage = this.currentPage - 1">
                 <span>Atrás</span>
            </div>
            <div class="page-button" v-if="this.currentPage > 4">...</div>
            <div :class="{ 'page-button': true, 'page-number': true, 'current-page': index === currentPage }"
                 v-for="index in pagesToShow" :key="index"
                 @click="this.currentPage = index"><span>{{ index }}</span>
            </div>
            <div class="page-button" v-if="(this.pages - this.currentPage) > 4"><span>...</span></div>
            <div class="page-button" v-if="this.currentPage < this.pages"
                 @click="this.currentPage = this.currentPage + 1"><span>Siguiente</span>
            </div>
        </div>
    </div>
</template>

<script>
import ProductComponent from "@/components/marketplace/ProductComponent.vue";
import axios from "axios";

export default {
    name: "ProductGridComponent",
    props: ['products'],
    watch: {
        products: function() {
            this.currentPage = 1
        }
    },
    components: {ProductComponent},
    data() {
        return {
            width: 0,
            currentPage: 1,
            numberOfProductsPerPage: 8,
            shopsInformation: []
        }
    },
    computed: {
        columnsInGrid() {
        /**
         * This function calculates the number of columns a grid should have, based on the width of the screen.
         */
            // Maximum width of a product card.
            const max_product_width = 240
            if (this.width < max_product_width+70) {
                return 1
            }
            else {
                return Math.floor(this.width / max_product_width)
            }
        },
        productsInCurrentPage() {
            return this.products.slice(this.numberOfProductsPerPage * (this.currentPage - 1), this.numberOfProductsPerPage * (this.currentPage))
        },
        pages() {
            var calculatedPages = Math.ceil(this.products.length / this.numberOfProductsPerPage)
            if (calculatedPages) {
                return calculatedPages
            } else {
                return 1
            }
        },
        pagesToShow() {
            var startBy = 1
            var endUntil = this.pages
            if (this.currentPage > 4) {
                startBy = this.currentPage - 3
            }
            if ((this.pages - this.currentPage) > 4) {
                endUntil = this.currentPage + 3
            }
            var pagesArray = []
            for (var i = startBy; i <= endUntil; i++) {
                pagesArray.push(i);
            }
            return pagesArray
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
    },
    mounted() {
        this.handleResize()
        this.calculateProductsInPage()
        this.getShopsInfo()
    },
    unmounted: function () {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.width = this.$refs.products.clientWidth;
            this.calculateProductsInPage()
        },
        calculateProductsInPage() {
            if (this.columnsInGrid < 3) {
                this.numberOfProductsPerPage = 8
            }
            if (this.columnsInGrid == 3) {
                this.numberOfProductsPerPage = 12
            }
            if (this.columnsInGrid == 4) {
                this.numberOfProductsPerPage = 16
            }
            if (this.columnsInGrid == 5) {
                this.numberOfProductsPerPage = 20
            }
            if (this.columnsInGrid == 6) {
                this.numberOfProductsPerPage = 24
            }
        },
        async getShopsInfo(){
            var url = process.env.VUE_APP_BASE_URL + '/shops'

            try {
                const response = await axios.get(url)
                this.shopsInformation = response.data;
                if (!this.products.length) {
                    this.emptySearch = true
                }
            } catch (error) {
                console.error(error)
            }
        }
    }
}
</script>

<style scoped>
.products-grid {
    margin: 0 auto;
    display: grid;
    gap: 0rem;
    grid-auto-columns: 1fr; /* Set the size of all grid items */
}

.pagination-wrapper {
    width: 100%;
    margin-top: 40px;
}

.pagination-wrapper .page-button {
    margin-top: 10px;
    font-size: 0.8em;
    cursor: pointer;
    display: inline-block;
    background-color: var(--background-gray);
    border-radius: 20px;
    min-width: 20px;
    margin-left: 3px;
    margin-right: 3px;
    border: 1px solid var(--background-dark-gray);
}

.page-button span {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    padding: 6px 10px;
}

.page-button.current-page {
    background-color: var(--background-dark-gray);
}

.pagination-wrapper .page-button:hover {
    background-color: var(--background-dark-gray)
}
</style>