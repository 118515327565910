<template>
  <div
    class="home"
    :style="{ backgroundImage: 'url(bbblurry-background.svg)' }"
  >
    <SearchBarHeaderComponent></SearchBarHeaderComponent>
    <div class="vertical-category-menu">
      <VerticalCategoryMenu></VerticalCategoryMenu>
    </div>
    <!--
    <div class="trending-wrapper">
      En tendencia ahora:
      <LabelForSearchComponent
        v-for="label in labels"
        :key="label.name"
        :filter-name="label.filterName"
        :name="label.name"
        :background-color="label.backgroundColor"
        :text-color="label.textColor"
        @click="searchWithLabel(label.filterName, label.value)"
      >
      </LabelForSearchComponent>
    </div>
    -->
    <div class="banner-wrapper">
      <BannerComponent></BannerComponent>
    </div>
    <div class="more-info" :style="{ paddingBottom: '30px' }">
      <div>
        En Close puedes encontrar
        <b>
          <count-up :end-val="nShops" :options="{ suffix: '' }"></count-up>
        </b>
        tiendas,&nbsp;
        <b>
          <count-up
            :end-val="listStyles.length"
            :options="{ suffix: '' }"
          ></count-up>
        </b>
        estilos,&nbsp;
        <b>
          <count-up :end-val="nColors" :options="{ suffix: '' }"></count-up>
        </b>
        colores y
        <b>
          <count-up
            :end-val="listPatterns.length"
            :options="{ suffix: '' }"
          ></count-up>
        </b>
        patrones&nbsp;
      </div>
    </div>
    <!--<div class="block">
      <div class="filters-title" v-if="listTrends.length">
        O inspirate con las siguientes temáticas
      </div>
      <Carousel
        v-bind="settings"
        :items-to-show="4"
        :breakpoints="breakpoints"
        :wrap-around="true"
      >
        <Slide v-for="trend in listTrends" :key="trend.trend_name">
          <div class="carousel__item">
            <FeaturedFilter
              :name="trend.trend_name"
              :image="trend.image"
              :filters="trend.filters"
            ></FeaturedFilter>
          </div>
        </Slide>

        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>
    </div>
    -->
    <div class="block social-block">
      <div class="block-header">
        <div class="title">Moda con propósito</div>
        <div class="tag">
          <img src="hands.png" alt="" height="80px" loading="lazy" />
        </div>
      </div>
      <div class="subtitle">
        Creemos en el poder de la moda para generar cambios. Nuestra selección
        de prendas con la etiqueta
        <LabelComponent
          name="social"
          @click="
            this.$router.push({ name: 'shop', query: { identity: 'social' } })
          "
        ></LabelComponent>

        te permite expresar tu solidaridad y apoyo a diversas iniciativas
      </div>
      <div class="products">
        <Carousel
          v-bind="settings"
          :items-to-show="4"
          :breakpoints="productBreakpoints"
          :wrap-around="true"
          :autoplay="5000"
        >
          <Slide
            v-for="product in listSocialProducts.slice(0, 10)"
            :key="product.id"
          >
            <div class="carousel__item">
              <ProductComponent
                :key="product.product_id"
                :product_id="product.product_id"
                :shopInfo="
                  shopsInformation.filter(
                    (shop) => shop.name == product.shop
                  )
                "
                :title="product.title"
                :images="product.images.slice(0, 5)"
                :price="product.price"
                :identityList="product.identity"
                :shop="product.shop"
                :url="product.url"
              ></ProductComponent>
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>
    <div class="block">
      <div class="block-title" v-if="listTrends.length">
        <div class="title">
          Explora nuestra variedad de patrones inspirados en la belleza de la
          naturaleza:
        </div>
        <div class="subtitle">
          Desde los sutiles matices de las flores hasta los audaces estampados
          de animales en Close.
        </div>
      </div>
      <Carousel
        v-bind="settings"
        :items-to-show="4"
        :breakpoints="breakpoints"
        :wrap-around="true"
        :autoplay="5000"
      >
        <Slide v-for="pattern in listPatterns" :key="pattern">
          <div class="carousel__item">
            <FeaturedPatternComponent
              :name="pattern"
              :filters="{ pattern: pattern }"
            >
            </FeaturedPatternComponent>
          </div>
        </Slide>
      </Carousel>
    </div>
    <div class="block ecological-block">
      <div class="block-header">
        <div class="title">
          <span>Viste con conciencia</span>
        </div>
        <div class="tag">
          <img src="leaf.png" alt="" height="80px" loading="lazy" />
        </div>
      </div>
      <div class="subtitle">
        <span>Cada prenda con la etiqueta</span>
        <LabelComponent
          name="ecological"
          @click="
            this.$router.push({
              name: 'shop',
              query: { identity: 'ecological' }
            })
          "
        ></LabelComponent>
        <span
          >en Close cuenta una historia de respeto por el medio ambiente.</span
        >
      </div>
      <div class="products">
        <Carousel
          v-bind="settings"
          :items-to-show="4"
          :breakpoints="productBreakpoints"
          :wrap-around="true"
          :autoplay="5000"
        >
          <Slide
            v-for="product in listEcologicalProducts.slice(0, 10)"
            :key="product.id"
          >
            <div class="carousel__item">
              <ProductComponent
                :key="product.product_id"
                :shopInfo="
                  shopsInformation.filter(
                    (shop) => shop.name == product.shop
                  )
                "
                :product_id="product.product_id"
                :title="product.title"
                :images="product.images.slice(0, 5)"
                :price="product.price"
                :identityList="product.identity"
                :shop="product.shop"
                :url="product.url"
              ></ProductComponent>
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>
    <div class="block">
      <div class="title">¿Te gustaría ver tu marca favorita en Close?</div>
      <div class="subtitle">
        Llenando el siguiente formulario nos ayudas a darle visibilidad a
        tiendas en tu zona.
      </div>

      <div class="propose-button">
        <button
          data-tf-popup="jXdVrqKy"
          data-tf-opacity="100"
          data-tf-size="100"
          data-tf-iframe-props="title=Proponer tienda"
          data-tf-transitive-search-params
          data-tf-medium="snippet"
          style="
            all: unset;
            font-family: Helvetica, Arial, sans-serif;
            display: inline-block;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            background-color: #dbeb2d;
            color: #000;
            font-size: 20px;
            border-radius: 25px;
            padding: 0 33px;
            font-weight: bold;
            height: 50px;
            cursor: pointer;
            line-height: 50px;
            text-align: center;
            margin: 0;
            text-decoration: none;
          "
        >
          Proponer una tienda
        </button>
      </div>
    </div>
    <div class="block">
      <img src="stars.png" alt="" height="100px" loading="lazy" />
      <div class="title">Califica tu experiencia en Close</div>
      <div class="subtitle">
        Como desarrolladores del sitio, es difícil darnos cuenta de qué hace
        falta.
      </div>

      <div class="propose-button">
        <button
          data-tf-popup="SC9iZIwP"
          data-tf-opacity="100"
          data-tf-size="100"
          data-tf-iframe-props="title=Experiencia en Close"
          data-tf-transitive-search-params
          data-tf-medium="snippet"
          style="
            all: unset;
            font-family: Helvetica, Arial, sans-serif;
            display: inline-block;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            background-color: #dbeb2d;
            color: #000;
            font-size: 20px;
            border-radius: 25px;
            padding: 0 33px;
            font-weight: bold;
            height: 50px;
            cursor: pointer;
            line-height: 50px;
            text-align: center;
            margin: 0;
            text-decoration: none;
          "
        >
          Califícanos
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CountUp from 'vue-countup-v3'
import axios from 'axios'
import { Slide, Carousel } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import SearchBarHeaderComponent from '@/components/general/SearchBarHeaderComponent.vue'
import VerticalCategoryMenu from '@/components/general/home/VerticalCategoryMenu.vue'
import BannerComponent from '@/components/home/BannerComponent.vue'
import FeaturedPatternComponent from '@/components/home/FeaturedPatternComponent.vue'
import LabelComponent from '@/components/marketplace/LabelComponent.vue'
import ProductComponent from '@/components/marketplace/ProductComponent.vue'

export default {
  name: 'HomePage',
  components: {
    ProductComponent,
    LabelComponent,
    FeaturedPatternComponent,
    BannerComponent,
    SearchBarHeaderComponent,
    Carousel,
    VerticalCategoryMenu,
    Slide,
    CountUp
  },
  data() {
    return {
      timer: null,
      currentSlide: 0,
      settings: {
        itemsToShow: 1,
        snapAlign: 'center'
      },
      breakpoints: {
        0: {
          itemsToShow: 1,
          snapAlign: 'center'
        },
        900: {
          itemsToShow: 2,
          snapAlign: 'center'
        },
        1200: {
          itemsToShow: 3,
          snapAlign: 'center'
        },
        1600: {
          itemsToShow: 4,
          snapAlign: 'start'
        }
      },
      productBreakpoints: {
        0: {
          itemsToShow: 1,
          snapAlign: 'center'
        },
        700: {
          itemsToShow: 2,
          snapAlign: 'center'
        },
        1200: {
          itemsToShow: 3,
          snapAlign: 'center'
        },
        1600: {
          itemsToShow: 4,
          snapAlign: 'start'
        }
      },
      nShops: 0,
      nColors: 255 * 3,
      listTrends: [],
      listStyles: [],
      listPatterns: [],
      listEcologicalProducts: [],
      listSocialProducts: [],
      options_shops: {
        suffix: ' tiendas'
      },
      options_brands: {
        suffix: ' tiendas'
      },
      labels: [],
      shopsInformation: []
    }
  },
  mounted() {
    var url_get_labels = process.env.VUE_APP_BASE_URL + '/trending_labels'
    axios
      .get(url_get_labels)
      .then(
        (response) => {
          this.labels = response.data
        },
        (error) => {
          console.error(error)
        }
      )
      .catch((error) => {
        console.error(error)
      })

    var url_get_shops = process.env.VUE_APP_BASE_URL + '/shops'
    axios
      .get(url_get_shops)
      .then(
        (response) => {
          this.nShops = response.data.length
        },
        (error) => {
          console.error(error)
        }
      )
      .catch((error) => {
        console.error(error)
      })

    var url_get_products = process.env.VUE_APP_BASE_URL + '/products/search'
    axios
      .get(url_get_products + '?identity=social')
      .then(
        (response) => {
          this.listSocialProducts = response.data
        },
        (error) => {
          console.error(error)
        }
      )
      .catch((error) => {
        console.error(error)
      })

    axios
      .get(url_get_products + '?identity=ecological')
      .then(
        (response) => {
          this.listEcologicalProducts = response.data
        },
        (error) => {
          console.error(error)
        }
      )
      .catch((error) => {
        console.error(error)
      })

    var url_get_styles = process.env.VUE_APP_BASE_URL + '/styles'
    axios
      .get(url_get_styles)
      .then(
        (response) => {
          this.listStyles = response.data
        },
        (error) => {
          console.error(error)
        }
      )
      .catch((error) => {
        console.error(error)
      })

    var url_get_patterns = process.env.VUE_APP_BASE_URL + '/patterns'
    axios
      .get(url_get_patterns)
      .then(
        (response) => {
          this.listPatterns = response.data
        },
        (error) => {
          console.error(error)
        }
      )
      .catch((error) => {
        console.error(error)
      })

    var url_get_trends = process.env.VUE_APP_BASE_URL + '/trends'
    axios
      .get(url_get_trends)
      .then(
        (response) => {
          this.listTrends = response.data
        },
        (error) => {
          console.error(error)
        }
      )
      .catch((error) => {
        console.error(error)
      })

    this.getShopsInfo()
  },
  methods: {
    searchWithLabel(filterName, filterValue) {
      var params = {}
      params[filterName] = filterValue
      params[this.defaultFilterName] = this.defaultValueName
      this.$router.push({ path: '/shop', query: params, replace: true })
    },
    async getShopsInfo() {
      /**
       * Get the information of all shops in close
       */
      var url = process.env.VUE_APP_BASE_URL + '/shops'

      try {
        // Makes a request to the API
        const response = await axios.get(url)
        this.shopsInformation = response.data
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style scoped>
.home {
  padding-bottom: 10%;
  background-attachment: fixed;
  background-size: auto 180%;
}

.vertical-category-menu {
  background-color: white;
}

.filters-title {
  font-size: 1.2em;
  font-family: 'Poppins', sans-serif;
  padding-bottom: 40px;
  font-weight: lighter;
}

.trending-wrapper {
  margin-top: 15px;
  margin-bottom: 10px;
}

.search-bar-wrapper img {
  padding-top: 15px;
  height: 30px;
  filter: invert(100%) grayscale(1);
}

.featured-filters-block {
  margin-top: 70px;
  /*height: 325px;*/
  /*position: relative;*/
  /*overflow: hidden;*/
}

.more-info {
  color: black;
  font-size: 1.2em;
  padding: 10px 10px;
  font-weight: lighter;
}

.subtitle span {
  padding: 5px 10px;
  border-radius: 15px;
  background-color: #00000040 !important;
  color: white;
}

.more-info {
  padding-top: 30px;
}

.countup-wrap {
  display: inline-block;
  font-size: 1em;
}

.carousel__item {
  width: 100%;
}

.block-title {
  margin-top: 20px;
  margin-bottom: 50px;
}

.title {
  font-size: 1.8em;
  font-family: 'Poppins', sans-serif;
  margin-right: 10px;
}

.subtitle {
  margin-top: 10px;
  font-size: 1.2em;
}

.block {
  border-top: 1px solid var(--background-dark-gray);
  padding-top: 60px;
  padding-bottom: 60px;
  background-position: center;
  background-position-y: center;
  /*filter: saturate(0%) brightness(50%);*/
  background-repeat: no-repeat;
  background-size: 150% auto;
  padding-left: 5%;
  padding-right: 5%;
}

.block .products {
  margin-top: 40px;
}

span {
  background-color: #00000080;
  color: white;
  padding: 3px 10px;
  border-radius: 15px;
}

.block.social-block {
  background-color: #ffffff70;
}

.block.ecological-block {
  padding-top: 150px;
  padding-bottom: 150px;
  background-color: #ffffff70;
}

.propose-button {
  margin-top: 30px;
}

.block-header {
  display: flex;
  width: 100%;
  justify-content: center;
}

.tag {
  height: 40px;
  width: 40px;
  position: relative;
  background-color: #fcfcfc;
  border: 1px solid var(--background-dark-gray);
  border-radius: 30px;
}

.tag img {
  height: 25px;
  position: absolute;
  width: 25px;
  top: 5px;
  left: 7px;
  filter: invert(10%) opacity(80%);
}
</style>
