<template>
  <div class="header">
    <div class="header-logo-wrapper">
      <LogoComponent color="black" :height="100"></LogoComponent>
      <!--        {{ process.env.BASE_URL }}-->
    </div>
  </div>
</template>

<script>
import LogoComponent from '@/components/general/LogoComponent.vue'

export default {
  name: 'SimpleHeaderComponent',
  components: { LogoComponent }
}
</script>

<style scoped>
.header {
  padding-top: 70px;
  padding-bottom: 40px;
  margin: 0px auto 0 auto;
  min-width: 250px;
  vertical-align: middle;
  width: 100%;
  /*border-bottom: 1px solid var(--background-dark-gray);*/
}

.header .header-logo-wrapper {
  padding-left: 20px;
  margin-top: 20px;
  margin-left: 0px;
  min-width: 250px;
  vertical-align: middle;
  width: 10%;
  display: inline-block;
}
</style>
