<template>
    <HelpPageTemplate>
        <div class="remove-shop-page">
            <div class="title">Reportar una tienda</div>
            <div class="content">
                <p>
                    En Close, nos esforzamos por brindar a nuestros usuarios la mejor experiencia al buscar y comprar
                    ropa en línea. Sin embargo, entendemos que pueden surgir ocasiones en las que tengas una mala
                    experiencia con una tienda en particular. Queremos que sepas que valoramos tus comentarios y estamos
                    aquí para ayudarte. Si has tenido una mala experiencia con una tienda y crees que no cumple con los
                    estándares de calidad, te invitamos a que nos lo informes a través de nuestro formulario de reporte
                    de tiendas.
                </p>
                <div class="subtitle">Limitación de responsabilidad</div>
                <p>
                    En Close, nos esforzamos por proporcionar información precisa y actualizada sobre las tiendas y los
                    productos que se muestran en nuestra plataforma. Sin embargo, queremos dejar en claro que Close no
                    es responsable de las transacciones, el cumplimiento de los pedidos ni la calidad de los productos
                    vendidos por las tiendas externas. Actuamos como un buscador web que redirige a los usuarios a los
                    sitios web originales de las tiendas. Por lo tanto, no podemos asumir la responsabilidad por las
                    acciones o políticas de las tiendas individuales.
                </p>
                <p>
                    Entendemos la importancia de proteger los derechos de los consumidores y trabajamos diligentemente
                    para garantizar que las tiendas que se muestran en Close cumplan con los estándares y las leyes
                    pertinentes. Si recibimos reportes de mala conducta o incumplimiento por parte de una tienda,
                    evaluaremos cada caso de manera individual y, si es necesario, tomaremos medidas, como eliminar la
                    tienda de nuestra plataforma. Sin embargo, no podemos garantizar resultados específicos ni resolver
                    disputas entre los usuarios y las tiendas externas.
                </p>
                <div class="subtitle">Realiza un reclamo a la tienda</div>
                <p>Valoramos tus derechos como consumidor y te alentamos a que, en primer lugar, intentes resolver
                    cualquier problema directamente con la tienda en cuestión. Según los derechos que te otorga la
                    legislación colombiana, como consumidor tienes el derecho de presentar reclamos por incumplimiento
                    en los tiempos de entrega u otras obligaciones a las que un comercio en internet está sujeto a
                    cumplir. Al realizar un reclamo directamente a la tienda, les das la oportunidad de rectificar la
                    situación y cumplir con sus responsabilidades como comercio electrónico en Colombia.</p>
                <p>
                    Si después de realizar el reclamo consideras que la tienda no respeta tus derechos como consumidor
                    colombiano, te invitamos a llenar nuestro formulario de reporte de tiendas. En Close, nos tomamos
                    muy en serio la protección de los consumidores y no queremos mostrar productos ni promover tiendas
                    que no cumplan con los estándares legales y éticos establecidos en Colombia. Evaluaremos tu caso de
                    manera cuidadosa y, si es necesario, procederemos a tomar medidas, como la eliminación de la tienda
                    de nuestra plataforma.
                </p>
                <p>
                    Recuerda que como consumidor, tienes derechos y las tiendas en línea tienen la obligación de cumplir
                    con sus deberes, tales como el cumplimiento de los tiempos de entrega, la calidad de los productos y
                    la protección de tus datos personales. Trabajamos para mantener un entorno seguro y confiable para
                    los usuarios de Close, y tu colaboración al informarnos de cualquier inconveniente nos ayuda a
                    lograrlo.
                </p>
            </div>
            <div class="report-button">
                <button data-tf-popup="Ev488d7v" data-tf-opacity="100" data-tf-size="100"
                        data-tf-iframe-props="title=Reportar tienda" data-tf-transitive-search-params
                        data-tf-medium="snippet"
                        style="all:unset;font-family:Helvetica,Arial,sans-serif;display:inline-block;max-width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;background-color:#DBEB2D;color:#000;font-size:20px;border-radius:25px;padding:0 33px;font-weight:bold;height:50px;cursor:pointer;line-height:50px;text-align:center;margin:0;text-decoration:none;">
                    Reportar tienda
                </button>
            </div>
        </div>
    </HelpPageTemplate>
</template>

<script>
import HelpPageTemplate from "@/pages/help/HelpPageTemplate.vue";

export default {
    name: "ReportShop",
    components: {HelpPageTemplate}
}
</script>

<style scoped>
.report-button {
    margin-top: 60px;
}
</style>