<template>
    <div class="marketplace-page">
        <div class="body">
            <SearchBarHeaderComponent :searchingProducts="this.resetFieldsInSearch"></SearchBarHeaderComponent>
            <div class="filter-toggle" @click="showFilter = !showFilter">
                <img src="menu.svg" alt="" loading="lazy">
            </div>
            <VerticalCategoryMenu></VerticalCategoryMenu>
            <div class="body-wrapper">
                <div class="filter-wrapper" :class="{ 'show': showFilter }">
                    <FilterComponent :productFilters="productFilters"></FilterComponent>
                </div>
                <div class="marketplace-content-wrapper">
                    <div class="ads-wrapper">
                        <div class="ads-banner">
                            <div class="ad-container">
<!--                                <Adsense-->
<!--                                    data-ad-client="ca-pub-1186879900424218"-->
<!--                                    data-ad-slot="2159303367"-->
<!--                                    data-ad-format="auto"-->
<!--                                    style="width: 100%; min-width: 250px"-->
<!--                                    ins-style="display:inline-block;width:100%;height:90px"-->
<!--                                >-->
<!--                                </Adsense>-->
                            </div>
                        </div>
                    </div>
                    <div class="content-wrapper">
                        <div class="filter-vertical-wrapper">
                            <FilterVerticalComponent @selectedSortBy="sortBy"></FilterVerticalComponent>
                        </div>
                        <div class="products-wrapper">
                            <div class="loading-wrapper" v-if="loadingProducts">
                                <img src="loading-black.svg" alt="" loading="lazy">
                            </div>
                            <div class="empty-search" v-if="emptySearch">
                                <div class="empty-search-title">
                                    Lamentamos no encontrar lo que buscas.
                                </div>
                                <p>
                                    Podrías reducir el número de filtros o probar diferentes
                                    palabras.
                                    Seguimos trabajando en incluir más productos y mejorar nuestra experiencia de
                                    búsqueda.
                                </p>
                                <p class="empty-search-options">
                                    Adicionalmente, para ayudarnos a incluir más productos podrías <a
                                        href="/help/propose-shop">proponer
                                    una tienda</a>.
                                </p>
                            </div>
                            <ProductGridComponent :products="this.products"></ProductGridComponent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import FilterComponent from "@/components/marketplace/FilterComponent.vue";
import {getProductFilters} from '@/utils.js';
import FilterVerticalComponent from "@/components/marketplace/FilterVerticalComponent.vue";
import ProductGridComponent from "@/components/marketplace/ProductGridComponent.vue";
import SearchBarHeaderComponent from "@/components/general/SearchBarHeaderComponent.vue";
import VerticalCategoryMenu from "@/components/general/home/VerticalCategoryMenu.vue";

export default {
    name: 'MarketplacePage',
    components: {
        ProductGridComponent,
        SearchBarHeaderComponent,
        FilterVerticalComponent,
        FilterComponent,
        VerticalCategoryMenu
    },
    data() {
        return {
            width: 0,
            emptySearch: false,
            visible: true,
            query: "",
            showFilter: false,
            labels: [],
            products: [],
            favorites: [],
            abortController: null,
            loadingProducts: false,
            sortByValue: ''
        }
    },
    computed: {
        productFilters() {
            return getProductFilters(this.products)
        },
    },
    created() {
        this.$watch('$route.query', this.handleQueryChange);
    },
    mounted() {
        this.updateProducts()
    },
    methods: {
        resetFieldsInSearch() {
            this.loadingProducts = true;
            this.emptySearch = false;
            this.products = []
        },
        sortBy(value) {
            this.sortByValue = value
            if (value === "low-to-high") {
                this.products.sort((a, b) => a.price - b.price);
            } else if (value === "high-to-low") {
                this.products.sort((a, b) => b.price - a.price);
            } else if (value === "rating") {
                this.products.sort((a, b) => b.rating - a.rating);
            }
        },
        handleQueryChange() {
            this.resetFieldsInSearch()
            this.updateProducts()
        },
        async updateProducts() {
            this.emptySearch = false
            this.products = []
            this.loadingProducts = true
            this.currentPage = 1
            if (this.abortController) {
                this.abortController.abort()
            }

            const abortController = new AbortController()
            this.abortController = abortController

            var url = process.env.VUE_APP_BASE_URL + '/products/search?'

            var validKeys = ['category', 'color', 'style', 'pattern', 'long', 'fit', 'sleeve', 'effect', 'shop', 'identity', 'details', 'waist']
            var params = this.$route.query
            for (let key in params) {
                if (validKeys.includes(key)) {
                    url = url + key + "=" + params[key] + "&"
                }
            }

            try {
                const response = await axios.get(url, {signal: abortController.signal})
                this.loadingProducts = false;
                this.products = response.data;
                if (!this.products.length) {
                    this.emptySearch = true
                }
            } catch (error) {
                console.error(error)
            }
            this.sortBy(this.sortByValue)
        }
    }
}
</script>

<style scoped>
.marketplace-page {
    position: relative;
}

.marketplace-page .marketplace-content-wrapper {
    padding-bottom: 60px;
    background-color: whitesmoke;
}

.body-wrapper {
    display: flex;
    flex-direction: row;
    min-height: 800px;
}

.header-wrapper {
    border-bottom: 2px solid var(--background-dark-gray);
    border-image: linear-gradient(to right, #DBEB2D 0%, #ff1e70 100%) 1;
    background-position: top;
    background-color: #00000003;
    /*background: linear-gradient(90deg, var(--starship) 0%, rgba(222, 217, 51, 1) 63%, rgba(241, 110, 86, 1) 90%, rgba(255, 30, 112, 1) 100%);*/
    width: 100%;
}

.labels {
    min-height: 60px;
    padding-top: 10px;
    padding-bottom: 20px;
}

.filter-toggle {
    top: 10px;
    left: 5px;
    z-index: 4;
    position: fixed;
}

.filter-toggle img {
    background-color: white;
    border-radius: 25px;
    padding: 10px;
    margin-left: 30px;
    height: 50px;
}

.filter-wrapper {
    background-color: #fdfdfd;
}

@media only screen and (max-width: 768px) {
    .filter-wrapper {
        position: fixed;
        padding-top: 40px;
        /* position: absolute; */
        top: 0;
        left: -120%;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        z-index: 3;
        padding-left: 30px;
        transition: all 0.3s ease;
    }

    .filter-wrapper.show {
        left: 0;
    }
}

@media only screen and (min-width: 769px) {
    .marketplace-page .filter-wrapper {
        border-right: 1px solid var(--background-dark-gray);
        width: 300px;
        min-width: 300px;
        padding-left: 30px;
        vertical-align: top;
        display: inline-block;
    }

    .marketplace-page .filter-toggle {
        display: none;
    }
}

.filter-vertical-wrapper {
    /*display: grid;*/
    /*place-items: start;*/
    padding-top: 15px;
    padding-bottom: 15px;
    /*justify-content: left;*/
}


.marketplace-content-wrapper {
    flex-grow: 1;
}

.loading-wrapper {
    margin-top: 100px;
    height: 150px;
}

.loading-wrapper img {
    width: 100px;
}


.content-wrapper {
    width: 100%;
}

.empty-search {
    padding-top: 50px;
    padding-left: 10%;
    text-align: justify;
    padding-right: 10%;
    align-content: center;
    align-items: center;
}

.empty-search-title {
    font-size: 1.3em;
}

.empty-search-options {
    font-size: 0.9em;
}

.header-wrapper {
    padding-top: 3px;
    padding-bottom: 10px;
    vertical-align: middle;
    width: 100%;
    display: flex;
    align-items: center;
}

.header-wrapper .header-logo-wrapper {
    padding-left: 20px;
    margin-top: 20px;
    margin-left: 0px;
    min-width: 250px;
    vertical-align: middle;
    width: 10%;
}

.header-wrapper .header-search-bar-wrapper {
    display: inline-block;
    width: 65%;
}

.header-wrapper .header-options-wrapper {
    display: none;
    width: 20%;
}

@media (max-width: 768px) {
    .header-wrapper {
        flex-wrap: wrap;
        justify-content: space-between;
        height: auto;
    }

    .header-wrapper .header-logo-wrapper {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }

    .header-wrapper .header-search-bar-wrapper {
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        margin-bottom: 10px;
    }

    .header-wrapper .header-options-wrapper {
        width: 100%;
    }
}

.filter-vertical-wrapper {
    border-bottom: 1px solid var(--background-dark-gray);
}

.header-wrapper {
    background-position: center;
    /*filter: saturate(0%) brightness(50%);*/
    background-repeat: no-repeat;
    background-size: 180% auto;
}
</style>
