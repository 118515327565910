<template>
    <InfoPageTemplate>
        <div class="about-us">
            <div class="grid-container">
                <div class="grid-item title" style="text-align: left;">Un buscador web de moda.</div>
                <div class="grid-item description" style="text-align: justify; font-size: 1.2em">
                    Close es un buscador web que reúne miles de productos de moda colombiana. Nos dedicamos a resaltar
                    la belleza y la autenticidad de las tiendas locales, ofreciendo a nuestros usuarios una amplia gama
                    de estilos y diseños.

                </div>
            </div>
            <div class="block">
                <div class="banner-slogan">
                    {{ animatedTexts[0] }}
                </div>
            </div>
            <div class="block">
                <div class="super-title fade-in">¿Cómo surgió Close?</div>
                <div class="super-subtitle">Close surge de la intersección entre el amor por la moda y el amor por la
                    tecnología.
                </div>
                <div class="about-us-content">
                    <p>
                        Iniciamos este
                        proyecto con el objetivo de hacer más eficiente la búsqueda de catálogos de ropa, pero durante
                        nuestra
                        exploración nos encontramos con algo maravilloso. Quedamos impresionados por el talento y la
                        originalidad de las tiendas locales que nos había llevado días descubrir.
                    </p>
                    <p>
                        Ese fue el momento en que
                        supimos que debíamos continuar y construir un buscador de software eficiente y veloz, pero
                        enfocado en darle visibilidad a estas tiendas creativas y originales que ansían ser
                        descubiertas.
                    </p>
                    <p>
                        En Close, nos emociona
                        conectar a nuestros usuarios con tiendas locales, tiendas que se ajustan a su estilo,
                        personalidad, identidad y valores.
                    </p>
                </div>
            </div>
            <div class="block" style="border-radius: 20px">
                <div class="banner-slogan">
                    {{ animatedTexts[1] }}
                </div>
            </div>
            <div class="block">
                <div class="super-title fade-in">¿Cómo funciona Close?</div>
                <div class="super-subtitle">Creemos en la inteligencia artificial para solucionar problemas reales.
                </div>
                <div class="grid-container">
                    <div class="grid-item">
                        <div>
                            <img src="https://close-images.s3.amazonaws.com/tagged_image.png" alt="Una mujer usando ropa, cada prenda fue etiquetada por un modelo de inteligencia artificial" style="border-radius: 20px; width: 90%" loading="lazy">
                        </div>
                        <div>
                            Photo by <a
                                href="https://unsplash.com/@fearvi?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Max
                            Titov</a> on <a
                                href="https://unsplash.com/photos/Mhktr6dFD3I?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>

                        </div>
                    </div>
                    <div class="grid-item"
                         style="text-align: left; font-size: 1.2em; display: flex; align-items: center">
                        <div>
                            <div>
                                Nuestros modelos de Inteligencia Artificial identifican características en los productos
                                para facilitar la búsqueda de nuestros usuarios.
                            </div>
                            <div style="margin-top: 20px">Actualmente incluimos más de 20 categorías de ropa.</div>

                        </div>
                    </div>
                </div>
                <div class="grid-container">
                    <div class="grid-item"
                         style="text-align: right; font-size: 1.2em; display: flex; align-items: center">
                        La integración con la tecnología nos permite agregar cientos de productos en minutos.
                    </div>
                    <div class="grid-item" style="text-align: justify; font-size: 1.2em">
                        <img src="https://close-images.s3.amazonaws.com/tagged_images.png" alt="Una mujer usa un abrigo de leopardo, el modelo de inteligencia artificial detectó el patrón" style="border-radius: 20px; width: 90%" loading="lazy">
                    </div>
                </div>
            </div>
            <div class="block">
                <div class="super-title fade-in">¿Cómo estamos comprometidos con Colombia?</div>
                <div class="super-subtitle">Incluimos y promovemos los productos con identidad.</div>
                <p>
                    Close es un lugar en donde los usuarios tienen la oportunidad de elegir. Por eso brindamos
                    información sobre la identidad de la marca cuando la conocemos. Los productos comprometidos con
                    Colombia y el mundo son resaltados en Close.
                </p>
                <p style="margin-bottom: 80px">
                    Esto permite que las marcas con identidad lleguen a usuarios que no están explícitamente buscando
                    productos ecológicos o sociales.
                </p>
                <div class="grid-container">
                    <div class="grid-item"
                         style="text-align: right; font-size: 1.2em; display: flex; align-items: center">
                        En nuestro buscador, los usuarios podrán encontrar una etiqueta y un mensaje de la marca cuando
                        esta tiene un compromiso ecológico.
                    </div>
                    <div class="grid-item" style="text-align: justify; font-size: 1.2em">
                        <img src="https://close-images.s3.amazonaws.com/eco-tag.jpeg" alt="Una mujer usa una blusa beige y hay una etiqueta ecológica en la imagen" max-height="400" style="border-radius: 20px; width:90%" loading="lazy">
                    </div>
                </div>
                <div class="grid-container">
                    <div class="grid-item" style="text-align: justify; font-size: 1.2em">
                        <img src="https://close-images.s3.amazonaws.com/social-tag.jpeg" alt="Una mujer usa una blusa blanca y hay una etiqueta social en la imagen" max-height="400" style="border-radius: 20px; width:90%" loading="lazy">
                    </div>
                    <div class="grid-item"
                         style="text-align: left; font-size: 1.2em; display: flex; align-items: center">
                        Algo similar sucede con las marcas que tienen un compromiso social.
                    </div>
                </div>
            </div>
            <div class="block" style="width: 90%">
                <div class="super-title fade-in">Contáctanos</div>
                <div style="margin-top: 50px">Estamos felices de escucharte, en el siguiente formulario puedes dejarnos un mensaje. También puedes escribir al correo
                    <a href="mailto:info@close.com.co">info@close.com.co</a> .</div>
                <div class="" style="margin-top: 40px">
                    <button data-tf-popup="ocQN54iM" data-tf-opacity="100" data-tf-size="100"
                            data-tf-iframe-props="title=Contáctanos" data-tf-transitive-search-params
                            data-tf-medium="snippet"
                            style="all:unset;font-family:Helvetica,Arial,sans-serif;display:inline-block;max-width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;background-color:#DBEB2D;color:#000;font-size:14px;border-radius:27px;padding:0px 20px;font-weight:bold;height:55px;cursor:pointer;line-height:55px;text-align:center;margin:0;text-decoration:none;">
                        Déjanos un mensaje
                    </button>
                </div>
            </div>
        </div>
    </InfoPageTemplate>
</template>

<script>

import InfoPageTemplate from "@/pages/InfoPageTemplate.vue";

export default {
    name: "AboutUsPage",
    components: {InfoPageTemplate},
    data () {
        return {
            texts: ['La moda es el lenguaje silencioso que habla por ti.', 'Brindamos a los usuarios la posibilidad de elegir aquí.'],
            animatedTexts: ['', ''],
            speed: 60,
        }
    },
    methods: {
        typeSlogan(index) {
            let i = 0;
            const intervalId = setInterval(() => {
                if (i < this.texts[index].length) {
                     this.animatedTexts[index] += this.texts[index].charAt(i);
                    i++;
                } else {
                    clearInterval(intervalId);
                }
            }, this.speed);
        },
    },
    mounted() {
        this.typeSlogan(0);
        this.typeSlogan(1);

  },
}
</script>

<style scoped>
.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns with equal width */
    grid-gap: 10px; /* Gap between grid items */
}

.grid-item {
    text-align: right;
    padding: 20px;
}

.about-us-content {
    margin-top: 20px;
}

.block {
    margin-top: 80px;
    padding: 30px 5px;
}

.block .super-title {
    margin-top: 30px;
}

.super-title {
    font-family: 'Poppins', sans-serif;
    font-size: 2.3em;
}

.super-subtitle {
    font-family: 'Poppins', sans-serif;

    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 1.5em;
}

p {
    text-align: justify;
    font-size: 1.1em;
}

.column-images img {
    /*display: inline-block;*/
    width: 400px;
}

h2 {
    font-weight: normal;
}

.block-content {
    margin-top: 30px;
    margin-top: 20px;
}

/* Media query for screens smaller than 600px (phones) */
@media (max-width: 600px) {
    .grid-container {
        grid-template-columns: 1fr; /* Single column */
    }
}

img.image-banner {
    width: 90%;
}

.banner-slogan{
    font-family: 'Poppins', sans-serif;
    font-size: 1.5em;
}

.fade-in {
  animation: fadeIn 2s ease-in-out forwards; /* Duración, función de temporización y retención del estado final */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>