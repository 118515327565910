<template>
  <div
    class="featured-pattern"
    @click="this.$router.replace({ name: 'shop', query: filters })"
    :style="{ backgroundImage: 'url(patterns/' + this.name + '.jpeg)' }"
  >
    <div class="featured-pattern-label">
      <LabelComponent :name="name"></LabelComponent>
    </div>
  </div>
</template>

<script>
import LabelComponent from '@/components/marketplace/LabelComponent.vue'

export default {
  name: 'FeaturedPattern',
  components: { LabelComponent },
  props: ['name', 'filters']
}
</script>

<style scoped>
.featured-pattern {
  border: solid 1px var(--background-dark-gray);
  border-right: none;
  padding-bottom: 5px;
  margin: auto 20%;
  margin-bottom: 80px;
  height: 300px;
  width: 300px;
  border-radius: 150px;
  background-size: auto 100%;
  background-position-x: center;
}

.featured-pattern:hover {
  cursor: pointer;
}

.featured-pattern-label {
  margin: 90% auto;
}

.label {
  padding: 8px 20px;
  border-radius: 20px;
  background-color: #fefefe !important;
  border-color: #000 !important;
  box-shadow: rgba(17, 12, 46, 0.06) 0px 18px 30px 0px;
}
</style>
