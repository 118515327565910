<template>
  <div class="banner" :style="{ 'background-image': currentBanner }">
    <div class="search-header-wrapper">
      <div class="subtitle fade-transition">
        <span>{{ currentText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @name BannerComponent
 * @description This component renders the main banner of the home page.
 */


export default {
  name: 'BannerComponent',
  components: {  },
  data() {
    return {
      sloganMessages: [
        'Apoyamos el comercio local.',
        'Usamos la tecnología para ejecutar ideas.',
        'Impulsamos a los pequeños emprendimientos.',
        'Confiamos en la inteligencia artificial.',
        'Apoyamos la moda como forma personal de expresión.',
        'Creémos en las segundas oportunidades.'
      ],
      banners: ['url(banner/1.png)','url(banner/2.png)', 'url(banner/3.png)', 'url(banner/4.png)'],
      currentIndex: 0,
      currentIndexBanner: 0
    }
  },
  created() {
    this.timer = setInterval(() => {
      this.changeText()
    }, 5000)
    this.timer = setInterval(() => {
      this.changeBanner()
    }, 10000)
  },
  methods: {
    changeText() {
      this.currentIndex = (this.currentIndex + 1) % this.sloganMessages.length
    },
    changeBanner() {
      this.currentIndexBanner =
        (this.currentIndexBanner + 1) % this.banners.length
    }
  },
  computed: {
    currentText() {
      return this.sloganMessages[this.currentIndex]
    },
    currentBanner() {
      return this.banners[this.currentIndexBanner]
    }
  }
}
</script>

<style scoped>
.banner { 
  color: white;
  width: 100%;
  padding-top: 20%;
  padding-bottom: 10%;
  z-index: 3;
  background-position: center;
  height: auto;
  background-size: cover;
  width: 100%;
}

span {
  /* background-color: #00000060; */
  text-shadow: #000 8px 0 10px;
  padding: 3px 10px;
  border-radius: 10px;
  font-size: 2em;
}
</style>
