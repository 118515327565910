export function mergeDictionaries(dict1, dict2) {
    /*
    * This function takes two dictionaries and merges them into one.
    * If a key exists in both dictionaries, the values are concatenated.
    * @param dict1: a dictionary
    * @param dict2: a dictionary
    * @return: a dictionary
    * 
    * Example:  
    * dict1 = {'a': 1, 'b': 2, 'c': 3}
    * dict2 = {'b': 4, 'c': 5, 'd': 6}
    * mergeDictionaries(dict1, dict2) = {'a': 1, 'b': [2, 4], 'c': [3, 5], 'd': 6}
    */

    const mergedDict = {};

    // Merge keys from dict1
    for (const key in dict1) {
        if (key in dict2) {
            let values = [dict1[key], dict2[key]];
            mergedDict[key] = values;
        } else {
            mergedDict[key] = dict1[key];
        }
    }

    // Merge keys from dict2
    for (const key in dict2) {
        if (!(key in dict1)) {
            mergedDict[key] = dict2[key];
        }
    }

    return mergedDict;
}


export function removeKeyValue(dict, key_name, value_name) {
    /*
    * This function takes a dictionary and removes a key-value pair from it.
    * @param dict: a dictionary
    * @param key_name: a sting. The key to remove
    * @param value_name: a string. The value to remove
    * @return: a dictionary
    * 
    * Example:
    * dict = {'a': 1, 'b': [2, 3, 4, 5], 'c': 3}
    * removeKeyValue(dict, 'b', 2) = {'a': 1, 'b': [3, 4, 5], 'c': 3}
    */

    if (key_name in dict) {
        let values = dict[key_name];
        if (Array.isArray(values)) {
            dict[key_name] = values.filter(value => value !== value_name);
            if (dict[key_name].length == 0 ){
                delete dict[key_name]
            }
        } else {
            if (values === value_name) {
                delete dict[key_name];
            }
        }
    }
    return dict;
}
