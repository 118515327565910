import { createApp } from 'vue'
import App from './App.vue'
import ScriptX from 'vue-scriptx'
import Ads from 'vue-google-adsense'
import router from './router'
import translations from './translations.js'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import { createI18n } from 'vue-i18n'
import VueGtag from 'vue-gtag'


const app = createApp(App)
const options = {
  // You can set your default options here
}

/* eslint-disable */
const i18n = createI18n({
  locale: 'es',
  fallbackLocale: 'es',
  legacy: false,
  globalInjection: true,
  messages: translations,
  runtimeOnly: false
})

app.use(Toast, options)
app.config.productionTip = false

app.use(require('vue-script2'))

app.use(ScriptX)
app.use(Ads.Adsense)
app.use(Ads.InArticleAdsense)
app.use(Ads.InFeedAdsense)
app.use(router)
app.use(VueGtag, {
  property: {
    id: 'G-HTBYR02M59'
  }
})
app.use(i18n)
app.mount('#app')
