<template>
    <div class="filter">
        <!--        Show other filters-->
        <div class="filter-block" v-for="(filterValues, filterKey) in productFilters" :key="filterKey">
            <div class="filter-block-content" v-if="filterValues.length">
                <div class="filter-name">
                    {{ $t(filterKey) }}
                </div>
                <div class="filter-content-absent" v-if="!filterValues.length">
                    ...
                </div>
                <div class="filter-content" v-if="filterValues.length">
                    <LabelComponent v-for="filterValue in productFilters[filterKey]" :key="filterValue"
                                    @click="this.addLabel(filterKey, filterValue); reportClick(filterValue)"
                                    :value="filterValue"
                                    :name="filterValue"></LabelComponent>
                </div>
            </div>
        </div>
        <!--        Show the color picker filter-->
        <div class="filter-block">
            <div class="filter-name">color</div>
            <div class="filter-content">
                Elegir color
                <input type="color" v-model="color" name="" id="input-color-picker"
                       @change="this.visibleButton = true">
                <div class="color-button" :style="{backgroundColor: this.color}" v-if="visibleButton"
                     @click="this.addLabel('color', this.color.slice(1));">
                    <div class="add-button">Buscar este color</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mergeDictionaries} from "@/utils/dictionaries.js";
import LabelComponent from "@/components/marketplace/LabelComponent.vue";

export default {
    name: "FilterComponent",
    props: ['productFilters'],
    components: {LabelComponent},
    data() {
        return {
            categories: [],
            color: '#D0DD42',
            visibleButton: false,
        }
    },
    methods: {
        reportClick(name) {
            this.$gtag.event('click', {
                event_category: 'filter',
                event_label: name
            })
        },
        addLabel: function (filterName, name) {
            var filter = {};
            filter[filterName] = [name];
            filter = mergeDictionaries(filter, this.$route.query)
            this.$router.push({path: '/shop', query: filter})
        },
    },
}
</script>

<style scoped>
.filter {
    /*position: fixed;*/
    overflow-y: scroll;
}

.filter .filter-block {
    margin-bottom: 20px;
}

.filter .filter-name {
    text-transform: uppercase;
  font-size: 0.9em;
  font-weight: bold;
    margin-top: 30px;
    text-align: left;
    margin-bottom: 8px;
}

.filter .filter-content {
    padding-right: 20px;
    text-align: left;
    max-height: 150px;
    overflow-y: scroll;
    font-weight: lighter;
}

.filter-content-absent {
    text-align: left;
    margin-bottom: 10px;
}

.filter .filter-content *:hover {
    cursor: pointer;
    color: #909090;
}

.filter-content .label {
    display: inline-block;
}

::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

/* Add a background color to the scrollbar */
::-webkit-scrollbar-track {
    background: #ffffff;
}

/* Add a darker color to the scrollbar thumb */
::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background: #bbb;
}

/* On hover, add a lighter color to the scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}

.color-button {
    font-size: 0.8em;
    border: 1px solid var(--background-dark-gray);
    border-radius: 15px;
    margin-top: 10px;
    padding: 5px 15px;
    height: 30px;
    display: flex;
    align-items: center;
}

.add-button {
    border-radius: 5px;
    display: inline-block;
    padding: 0px 5px;
    background-color: #FFFFFFa0;
    color: #333333;
}

.add-button:hover {
    color: #333333 !important;
}

.label {
    margin-top: 5px;
}
</style>