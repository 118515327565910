<template>
  <div class="footer">
    <div class="footer-column">
      <div class="logo">
        <LogoComponent color="black"></LogoComponent>
      </div>
    </div>
    <div class="footer-column">
      <div class="footer-title">Conócenos</div>
      <ul>
        <li>
          <div class="page" @click="this.$router.push({ name: 'about-us' })">
            <a href="">Acerca de nosotros</a>
          </div>
        </li>
        <li>
          <div class="page" @click="this.$router.push({ name: 'join-us' })">
            <a href="">Sé parte de la comunidad</a>
          </div>
        </li>
      </ul>
    </div>
    <div class="footer-column">
      <div class="footer-title">Legal</div>
      <ul>
        <li>
          <div
            class="page"
            @click="this.$router.push({ name: 'terms-and-conditions' })"
          >
            <a href="">Términos y condiciones</a>
          </div>
        </li>
        <li>
          <div
            class="page"
            @click="this.$router.push({ name: 'privacy-policy' })"
          >
            <a href="">Política de privacidad</a>
          </div>
        </li>
        <li>
          <div
            class="page"
            @click="this.$router.push({ name: 'cookie-policy' })"
          >
            <a href="">Política de cookies</a>
          </div>
        </li>
        <li>
          <div class="page" @click="this.$router.push({ name: 'help' })">
            <a href="">Ayuda / PQRS</a>
          </div>
        </li>
      </ul>
    </div>
    <div class="footer-column">
      <div class="footer-title">Conéctate</div>
      <div class="sns">
        <div
          class="social-network"
          v-for="sn in sns"
          :key="sn.name"
          @click="goTo(sn.link)"
        >
          <img class="icon" :src="sn.logo" :alt="sn.name" loading="lazy"/>
        </div>
      </div>
    </div>
    <div class="footer-copyright">
      Copyright © 2023. Prohibida la reproducción total o parcial sin
      autorización de Close.
    </div>
  </div>
</template>

<script>
/**
 * @name FooterComponent
 * @description This component renders the footer of the website
 */

import LogoComponent from '@/components/general/LogoComponent.vue'

export default {
  name: 'FooterComponent',
  components: { LogoComponent },
  data() {
    return {
      sns: [
        {
          name: 'instagram',
          link: 'https://www.instagram.com/close.com.co/',
          logo: '/social/instagram.svg'
        },
        {
          name: 'email',
          link: 'mailto:info@close.com.co',
          logo: '/social/mail.svg'
        },
        {
          name: 'x',
          link: 'https://twitter.com/closesearch',
          logo: '/social/twitterx.svg'
        }
        // {
        //     name: "tiktok",
        //     link: "",
        //     logo: "social/tiktok.svg"
        // },
      ]
    }
  },
  methods: {
    goTo(url) {
      window.location.href = url
    }
  }
}
</script>

<style scoped>
.footer {
  font-family: 'Figtree', sans-serif;
  display: flex;
  flex-wrap: wrap;
}

.footer {
  background-color: white;
  border-top: 1px solid var(--background-dark-gray);
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 30px;
  margin: 0px;
}

.footer a {
  color: black;
}

.footer .logo {
  vertical-align: top;
  display: inline-block;
}

.footer .page {
  font-size: 1em;
  text-align: left;
}

.footer .sns {
  display: inline-block;
  vertical-align: top;
  align-content: flex-end;
}

.footer .social-network {
  cursor: pointer;
  padding-left: 10px;
  display: inline-block;
}

.footer .social-network img {
  height: 32px;
}

.footer-column {
  width: 25%;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: left;
}

.footer-title {
  font-family: 'Figtree', sans-serif;
    
  font-weight: normal;
  text-align: left;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer li {
  margin-bottom: 5px;
}

.footer a {
  color: #555;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer .page {
  cursor: pointer;
}

@media (max-width: 992px) {
  .footer-column {
    width: 50%;
  }
}

@media (max-width: 576px) {
  .footer-column {
    width: 100%;
    margin-bottom: 30px;
  }
}

.footer-copyright {
  color: #909090;
  margin-top: 80px;
  font-size: 0.9em;
  width: 100%;
  align-content: center;
  text-align: center;
}
</style>
