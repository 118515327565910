<template>
    <HelpPage>
        <div class="remove-shop-page">
            <div class="title">Dar de baja una tienda</div>
            <div class="content">
                <p>En Close respetamos tu decisión y si eres el dueño y/o representante de una tienda, puedes solicitar el retiro
                    de la tienda de Close. Tan pronto recibamos la solicitud y verifiquemos que estás autorizado para realizar
                    el retiro, procederemos a eliminar la tienda inmediatamente. Para solicitar la baja escribe un correo a
                    <a href="mailto:pqrs@close.com.co">pqrs@close.com.co</a> con el asunto "Dar de baja una tienda". El correo debe incluir el nombre de la tienda, el nombre del titular y fotos que nos permitan verificar la identidad del titular.</p>

                <div class="subtitle">Verificar titularidad de la tienda</div>
                <p>En Close procuramos que solamente las personas autorizadas para hacerlo, tengan permiso de administrar los
                    productos y el nombre de la tienda. Por lo tanto, es necesario que verifiquemos tu identidad. Para lo
                    siguiente, solicitaremos dos archivos.</p>
                <ul>
                    <li>Foto del representante o titular de la tienda junto al documento de identidad. Para verificar tu identidad, deberás adjuntar al correo una foto tipo retrato en donde sostengas tu documento
                        de identidad junto a tu cara.</li>
                    <li>Foto del documento o documento escaneado del representante o titular de la tienda. En esta foto, el texto del documento en la foto deberá ser claro y legible.</li>
                </ul>
                <img src="/person-holding-id.png" alt="" height="300px" loading="lazy">
                <div style="display: inline-block; vertical-align: top">
                    Algunas opciones de documentos son:
                    <ul>
                        <li>Cédula de ciudadanía</li>
                        <li>Pasaporte</li>
                        <li>Licencia de conducción</li>
                    </ul>

                    Tener en cuenta:
                    <ul>
                        <li>Asegurate de que la foto esté bien iluminada y la cámara esté enfocando el documento.</li>
                        <li>Adjunta las fotos en cualquiera de los siguientes formatos: PNG, JPG, JPEG o PDF</li>
                    </ul>
                </div>

                <div class="subtitle">Lamentamos tener una tienda menos</div>
                <p>Una vez recibido el correo, la solicitud re procesará como PQR y procederemos al retiro de la tienda o a solicitar más información para verificar la titularidad. Esta solicitud se responderá durante el plazo estipulado en las leyes de protección al consumidor.</p>
            </div>
        </div>
    </HelpPage>
</template>

<script>
import HelpPage from "@/pages/help/HelpPageTemplate.vue";

export default {
    name: "RemoveShop",
    components: {HelpPage}
}
</script>

<style scoped>

</style>