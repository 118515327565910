<template>
  <div class="announcements-bar text-sans-serif">
    <!-- TODO: This message must come from the API -->
    En Close queremos ayudar a las pequeñas y medianas tiendas colombianas a
    llegar a más personas a través de internet.
  </div>
</template>

<script>
/**
 * @name AnnouncementsBar
 * @description This component renders the announcements bar at the top of the website.
 */

export default {
  name: 'AnnouncementsBar'
}
</script>

<style scoped>
.announcements-bar {
  font-size: 0.9em;
  padding: 3px 10px;
  overflow: hidden;
  background: var(--starship);
  background: linear-gradient(
    90deg,
    var(--starship) 0%,
    rgba(222, 217, 51, 1) 63%,
    rgba(241, 110, 86, 1) 90%,
    rgba(255, 30, 112, 1) 100%
  );
}
</style>
