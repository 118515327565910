<template>
  <div class="search-bar-header">
    <div class="header-logo-wrapper">
      <LogoComponent color="black" :height="65"></LogoComponent>
    </div>
    <div class="header-search-bar-wrapper">
      <SearchBarComponent
        with-category="true"
        @searchingProducts="this.searchingProducts"
      ></SearchBarComponent>
    </div>
  </div>
</template>

<script>
import LogoComponent from '@/components/general/LogoComponent.vue'
import SearchBarComponent from '@/components/general/SearchBarComponent.vue'

export default {
  name: 'SearchBarHeaderComponent',
  components: { SearchBarComponent, LogoComponent },
  props: ['searchingProducts']
}
</script>

<style scoped>
.search-bar-header {
  border-bottom: 2px solid var(--background-dark-gray);
  border-image: linear-gradient(to right, #dbeb2d 0%, #ff1e70 100%) 1;
  background-position: top;
  background-color: #ffffff;
  /*background: linear-gradient(90deg, var(--starship) 0%, rgba(222, 217, 51, 1) 63%, rgba(241, 110, 86, 1) 90%, rgba(255, 30, 112, 1) 100%);*/
  width: 100%;
}

.search-bar-header {
  padding-top: 15px;
  padding-bottom: 10px;
  vertical-align: middle;
  width: 100%;
  display: flex;
  align-items: center;
}

.search-bar-header .header-logo-wrapper {
  padding-left: 20px;
  margin-top: 10px;
  margin-left: 0px;
  min-width: 250px;
  vertical-align: middle;
  horiz-align: left;
  width: 10%;
}

.search-bar-header .header-search-bar-wrapper {
  display: inline-block;
  width: 65%;
}

@media (max-width: 768px) {
  .search-bar-header {
    flex-wrap: wrap;
    justify-content: space-between;
    height: auto;
  }

  .search-bar-header .header-logo-wrapper {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .search-bar-header .header-search-bar-wrapper {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 10px;
  }
}

.search-bar-header {
  background-position: center;
  /*filter: saturate(0%) brightness(50%);*/
  background-repeat: no-repeat;
  background-size: 180% auto;
}
</style>
