<template>
    <LegalPageTemplate>
        <div class="cookie-policy">
            <div class="title">Política de cookies</div>
            <div class="content">
                <h4>Actualizado al 20 de marzo de 2023</h4>

                <h3>Definiciones y términos clave</h3>

                Para ayudar a explicar las cosas de la manera más clara posible en esta Política de Cookies, cada vez
                que se
                haga referencia a cualquiera de estos términos, se definen estrictamente como:

                <ul>
                    <li>Cookie: pequeña cantidad de datos generados por un sitio web y guardados por su navegador web.
                        Se
                        utiliza para identificar su navegador, proporcionar análisis, recordar información sobre usted,
                        como
                        su preferencia de idioma o información de inicio de sesión.
                    </li>
                    <li>
                        Compañía: cuando esta política menciona "Compañía", "nosotros", "nos" o "nuestro", se refiere a
                        close, que es responsable de su información bajo esta Política de Cookies.
                    </li>
                    <li>
                        Dispositivo: cualquier dispositivo conectado a Internet, como un teléfono, tableta, computadora
                        o
                        cualquier otro dispositivo que se pueda utilizar para visitar close y utilizar los servicios.
                    </li>
                    <li>
                        Datos personales: cualquier información que, directa o indirectamente, o en conexión con otra
                        información, incluido un número de identificación personal, permita la identificación o
                        identificabilidad de una persona natural.
                    </li>
                    <li>
                        Servicio: se refiere al servicio proporcionado por close, según se describe en los términos
                        relativos (si están disponibles) y en esta plataforma.
                    </li>
                    <li>
                        Servicio de terceros: se refiere a anunciantes, patrocinadores de concursos, socios de promoción
                        y
                        marketing y otros que proporcionan nuestro contenido o cuyos productos o servicios creemos que
                        pueden interesarle.
                    </li>
                    <li>
                        Sitio web: el sitio de close, al que se puede acceder a través de esta URL: www.close.com.co.
                    </li>
                    <li>
                        Usted: una persona o entidad que está registrada en close para usar los Servicios.
                    </li>
                </ul>


                <h3>Introducción</h3>

                Esta Política de Cookies explica cómo close y sus afiliados (colectivamente "close", "nosotros", "nos" y
                "nuestros") utilizan cookies y tecnologías similares para reconocerlo cuando visita nuestro sitio web,
                incluido, entre otros, www.close.com.co y cualquier URL relacionada, versiones móviles o localizadas y
                dominios / subdominios relacionados ("Sitios web"). Explica qué son estas tecnologías y por qué las
                usamos,
                así como las opciones para controlarlas.


                <h3>¿Qué es una cookie?</h3>

                Una cookie es un pequeño archivo de texto que se almacena en su computadora u otro dispositivo conectado
                a
                Internet para identificar su navegador, proporcionar análisis, recordar información sobre usted, como su
                preferencia de idioma o información de inicio de sesión. Son completamente seguras y no se pueden
                utilizar
                para ejecutar programas o enviar virus a su dispositivo.


                <h3>¿Por qué usamos cookies?</h3>

                Utilizamos cookies de primera y/o tercera parte en nuestro sitio web para varios propósitos, como:

                <ul>
                    <li>Facilitar el funcionamiento y la funcionalidad de nuestro sitio web;</li>
                    <li>Mejorar su experiencia en nuestro sitio web y hacer que la navegación sea más rápida y fácil;
                    </li>
                    <li>Permitirnos crear una experiencia personalizada para usted y para nosotros entender qué es útil
                        o de
                        interés para usted;
                    </li>
                    <li>Analizar cómo se utiliza nuestro sitio web y cómo podemos personalizarlo mejor;</li>
                    <li>Identificar prospectos futuros y personalizar las interacciones de marketing y ventas con ellos;
                    </li>
                    <li>Facilitar la adaptación de la publicidad en línea a sus intereses.</li>
                </ul>


                <h3>¿Qué tipo de cookies utiliza close?</h3>
                Las cookies pueden ser cookies de sesión o cookies persistentes. Una cookie de sesión expira
                automáticamente
                cuando
                cierra su navegador. Una cookie persistente permanecerá hasta que expire o borre sus cookies. Las fechas
                de
                vencimiento
                se establecen en las propias cookies; algunas pueden expirar después de unos minutos, mientras que otras
                pueden
                expirar después de varios años. Las cookies colocadas por el sitio web que está visitando se llaman
                "cookies
                de
                primera parte".

                Las cookies estrictamente necesarias son necesarias para que nuestro sitio web funcione y no se pueden
                desactivar en
                nuestros sistemas. Son esenciales para permitirle navegar por el sitio web y utilizar sus funciones. Si
                elimina o
                desactiva estas cookies, no podemos garantizar que podrá utilizar nuestro sitio web.

                Utilizamos los siguientes tipos de cookies en nuestro sitio web:

                <h3>Exención de responsabilidad por errores y omisiones</h3>
                close no es responsable de ningún contenido, código o cualquier otra imprecisión.

                close no proporciona garantías ni garantías.

                <p>
                    En ningún caso close será responsable de ningún daño especial, directo, indirecto, consecuente o
                    incidental
                    o de cualquier otro tipo, ya sea en una acción de contrato, negligencia u otro delito, que surja de
                    o en
                    conexión con el uso del servicio o el contenido del servicio. close se reserva el derecho de hacer
                    adiciones, eliminaciones o modificaciones al contenido del servicio en cualquier momento sin previo
                    aviso.

                </p>
                <h3>Descargo de responsabilidad general</h3>
                El servicio close y sus contenidos se proporcionan "tal cual" y "según disponibilidad" sin garantía o
                representación de ningún tipo, ya sea expresa o implícita. close es un distribuidor y no un editor del
                contenido suministrado por terceros; como tal, close no ejerce control editorial sobre dicho contenido y
                no
                ofrece garantía o representación en cuanto a la exactitud, confiabilidad o actualidad de cualquier
                información, contenido, servicio o mercancía proporcionada a través del servicio close o accesible a
                través
                del mismo. Sin limitar lo anterior, close renuncia específicamente a todas las garantías y
                representaciones
                en cualquier contenido transmitido en o en conexión con el servicio close o en sitios que puedan
                aparecer
                como enlaces en el servicio close, o en los productos proporcionados como parte de, o de otra manera en
                conexión con, el servicio close, incluyendo sin limitación cualquier garantía de comercialización,
                aptitud
                para un propósito particular o no infracción de los derechos de terceros. Ningún consejo oral o
                información
                escrita proporcionada por close o cualquiera de sus afiliados, empleados, funcionarios, directores,
                agentes
                o similares creará una garantía. La información de precios y disponibilidad está sujeta a cambios sin
                previo
                aviso. Sin limitar lo anterior, close no garantiza que el servicio close será ininterrumpido, sin
                corrupción, oportuno o libre de errores.

                <h3>Cookies esenciales</h3>
                Utilizamos cookies esenciales para hacer que nuestro sitio web funcione. Estas cookies son estrictamente
                necesarias para habilitar la funcionalidad principal, como la seguridad, la gestión de redes, sus
                preferencias de cookies y la accesibilidad. Sin ellas, no podría utilizar servicios básicos. Puede
                desactivarlas cambiando la configuración de su navegador, pero esto puede afectar cómo funcionan los
                sitios
                web.

                <h3>Cookies de rendimiento y funcionalidad</h3>
                Estas cookies se utilizan para mejorar el rendimiento y la funcionalidad de nuestro sitio web, pero no
                son
                esenciales para su uso. Sin embargo, sin estas cookies, ciertas funcionalidades como los videos pueden
                volverse no disponibles o se le requeriría ingresar sus detalles de inicio de sesión cada vez que visite
                el
                sitio web, ya que no podríamos recordar que había iniciado sesión previamente.

                <h3>Cookies de marketing</h3>
                Estas cookies de marketing basadas en cuentas nos permiten identificar prospectos futuros y personalizar
                interacciones de ventas y marketing con ellos.

                <h3>Cookies de análisis y personalización</h3>
                <p>
                    Estas cookies recopilan información que se utiliza para ayudarnos a comprender cómo se utiliza
                    nuestro
                    sitio
                    web o qué tan efectivas son nuestras campañas de marketing, o para ayudarnos a personalizar nuestro
                    sitio
                    web para usted.
                </p>

                Utilizamos cookies proporcionadas por Google Analytics para recopilar datos limitados directamente de
                los
                navegadores de los usuarios finales para permitirnos comprender mejor el uso de nuestro sitio web. Se
                puede
                encontrar más información sobre cómo Google recopila y utiliza estos datos en:
                https://www.google.com/policies/privacy/partners/. Puede optar por no participar en todas las analíticas
                compatibles con Google en nuestros sitios web visitando: https://tools.google.com/dlpage/gaoptout.

                <h3>Cookies de redes sociales</h3>

                <p>
                    Estas cookies se utilizan cuando compartes información utilizando un botón de compartir en redes
                    sociales o
                    un botón de "me gusta" en nuestro sitio, o cuando vinculas tu cuenta o interactúas con nuestro
                    contenido
                    en
                    una red social como Facebook, Twitter o Google+. La red social registrará que has realizado esta
                    acción.
                    Estas cookies también pueden incluir cierto código que se ha colocado en la plataforma para ayudar a
                    realizar un seguimiento de las conversiones de anuncios, optimizar los anuncios en función de los
                    datos
                    recopilados, construir audiencias dirigidas para anuncios futuros y volver a comercializar a
                    usuarios
                    calificados que ya han realizado ciertas acciones en la plataforma.

                </p>

                <h3>¿Cómo puedes gestionar las cookies?</h3>

                La mayoría de los navegadores permiten controlar las cookies a través de sus preferencias de
                "configuración". Sin embargo, si limitas la capacidad de los sitios web para establecer cookies, es
                posible
                que empeores tu experiencia de usuario en general, ya que ya no estará personalizada para ti. También
                puede
                impedir que guardes ajustes personalizados, como información de inicio de sesión. Los fabricantes de
                navegadores ofrecen páginas de ayuda relacionadas con la gestión de cookies en sus productos.

                <p>
                    Los fabricantes de navegadores ofrecen páginas de ayuda relacionadas con la gestión de cookies en
                    sus
                    productos. Consulta a continuación para obtener más información.

                </p>

                <ul>
                    <li>Google Chrome</li>
                    <li>Internet Explorer</li>
                    <li>Mozilla Firefox</li>
                    <li>Safari (escritorio)</li>
                    <li>Safari (móvil)</li>
                    <li>Navegador de Android</li>
                    <li>Opera</li>
                    <li>Opera Mobile</li>
                </ul>


                <h3>Bloqueo y desactivación de cookies y tecnologías similares</h3>

                Independientemente de tu ubicación, también puedes configurar tu navegador para bloquear cookies y
                tecnologías similares, pero esta acción puede bloquear nuestras cookies esenciales y evitar que nuestro
                sitio web funcione correctamente, y es posible que no puedas utilizar todas sus funciones y servicios.
                También debes saber que también puedes perder cierta información guardada (por ejemplo, detalles de
                inicio
                de sesión guardados, preferencias del sitio) si bloqueas las cookies en tu navegador. Diferentes
                navegadores
                ofrecen diferentes controles disponibles para ti. Deshabilitar una cookie o categoría de cookie no borra
                la
                cookie de tu navegador, tendrás que hacerlo tú mismo desde dentro de tu navegador, debes visitar el menú
                de
                ayuda de tu navegador para obtener más información.

                <h3>Cambios en nuestra política de cookies</h3>

                Podemos cambiar nuestro servicio y nuestras políticas, y es posible que necesitemos hacer cambios en
                esta
                Política de cookies para que reflejen con precisión nuestro servicio y nuestras políticas. A menos que
                lo
                exija la ley, te notificaremos (por ejemplo, a través de nuestro servicio) antes de hacer cambios en
                esta
                Política de cookies y te daremos la oportunidad de revisarlos antes de que entren en vigor. Si sigues
                utilizando el servicio, estarás sujeto a la Política de cookies actualizada. Si no deseas aceptar esta o
                cualquier Política de cookies actualizada, puedes eliminar tu cuenta.


                <h3>Tu consentimiento</h3>

                Al utilizar nuestro sitio web, registrarte en una cuenta o realizar una compra, aceptas nuestro Política
                de cookies y estás de acuerdo con sus términos.


                <h3>Contacto</h3>

                No dudes en contactarnos si tienes alguna pregunta sobre nuestra Política de cookies.

                <ul>
                    <li>
                        Por correo electrónico: pqrs@close.com.co
                    </li>
                </ul>


            </div>
        </div>
    </LegalPageTemplate>
</template>

<script>
import LegalPageTemplate from "@/pages/legal/LegalPageTemplate.vue";

export default {
    name: "CookiePolicyPage",
    components: {LegalPageTemplate}
}
</script>

<style scoped>
</style>