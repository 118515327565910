import axios from 'axios';

export function hexToRgb(hex) {
    // Remove the # character if it exists
    hex = hex.replace("#", "");

    // Convert the hex string to a number
    const num = parseInt(hex, 16);

    // Extract the red, green, and blue components from the number
    const red = (num >> 16) & 255;
    const green = (num >> 8) & 255;
    const blue = num & 255;

    // Return the RGB color string
    return `rgb(${red}, ${green}, ${blue})`;
}


export function getColorInfo(color) {
    /*
    This function takes a hex color code and returns information of the color
    @param color: a hex color code
    */
    const url_with_color = "https://www.thecolorapi.com/id?format=json&hex=" + color;
    return axios.get(url_with_color).then(response => {
        return {
            'name': response.data.name.value,
            'contrast': response.data.contrast.value
        };
    }).catch(error => {
        console.error(error);
        return null;
    });
}